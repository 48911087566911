import { Component, ElementRef, Renderer2, inject } from '@angular/core';
import { LocalizationService } from '@core/services/api/bannerflow/localization.service';
import { ExObjectItem } from '@shared/classes/ExObjectQuery';
import { ObjectType } from '@shared/enums/objectType.enum';
import { Localization } from '@shared/models/version/localization.model';
import { firstValueFrom } from 'rxjs';
import { ObjectPickerComponent } from '../objectPicker.component';
import { FormsModule } from '@angular/forms';
import { DebounceInputDirective } from '../../../directives/debounceInput.directive';

import { UIModule } from '@bannerflow/ui';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
    selector: 'localizationPicker',
    templateUrl: 'localization.picker.component.html',
    styleUrls: ['../objectPicker.component.scss', './localization.picker.component.scss'],
    imports: [FormsModule, DebounceInputDirective, UIModule, NgxDatatableModule]
})
export class LocalizationPickerComponent extends ObjectPickerComponent {
    private readonly localizationService = inject(LocalizationService);
    public readonly elementRef = inject(ElementRef);
    public readonly renderer = inject(Renderer2);
    public type: ObjectType = ObjectType.Localization;

    public tableRows: (Localization | ExObjectItem<Localization>)[] = [];

    protected loadItems(): void {
        this.loading = true;
        firstValueFrom(this.localizationService.getLocalizations()).then(
            (localizations: Localization[]) => {
                localizations.sort((locationA, locationB) => {
                    if (locationA.mainLocalization || locationA.name < locationB.name) {
                        return -1;
                    } else if (locationA.name > locationB.name) {
                        return 1;
                    }
                    return 0;
                });

                this.tableRows = localizations;
                super.loadItems();
                this.datatable.recalculate();
                this.loading = false;
            }
        );
    }
}
