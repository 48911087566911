<div class="objectDropDown" [ngClass]="{ 'objectDropDown--active': selected && selected.length }">
    <bfDropDown (open)="onOpen()" (close)="onClose()">
        <div bfDropDownTitle>
            {{ label }}
            @if (multi && selected.length) {
<i
                aria-hidden="true"
                class="objectDropDown__clear bf-icon bf-icon-close-filled"
                (click)="clear($event)"
                (keydown)="clear($event)"
               
            ></i>
}
        </div>
        <div bfDropDownContent>
            @if (isOpen) {
<adGroupPicker
                #objectPicker
                [multi]="multi"
                [(selected)]="selected"
                (selectedChange)="change($event)"
                [placeholder]="placeholder"
                [allowNoneSelection]="allowNoneSelection"
               
                [campaignID]="campaignID"
            ></adGroupPicker>
}
        </div>
    </bfDropDown>
</div>
