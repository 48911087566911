import { AnalyticsApiService } from '@analytics/services/analyticsApi.service';
import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { Campaign } from '@shared/models/campaign/models/campaign.model';
import { ObjectDropDownComponent } from '../objectDropDown.component';
import { NgClass } from '@angular/common';
import { BFDropDownComponent } from '../../dropDown/bfDropDown.component';
import { AdGroupPickerComponent } from './adgroup.picker.component';

@Component({
    selector: 'adGroupDropDown',
    templateUrl: 'adgroup.dropdown.component.html',
    styleUrls: ['../objectDropDown.component.scss'],
    imports: [NgClass, BFDropDownComponent, AdGroupPickerComponent]
})
export class AdGroupDropDownComponent extends ObjectDropDownComponent {
    private readonly analyticsApiService = inject(AnalyticsApiService);
    @Input() public placeholder = 'No ad group selected';
    @Input() public campaignID: string;
    @Output() hasReportingLabel: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    public async ngOnInit(): Promise<void> {
        if (this.selected != null) {
            const campaign: Partial<Campaign> = await this.analyticsApiService.getCampaign(
                this.campaignID
            );
            const adGroupList = campaign.adGroups;
            const hasTrueReportingLabel = campaign.attempts.some(
                (publishAttempt) => publishAttempt.hasReportingLabel === true
            );
            this.hasReportingLabel.emit(hasTrueReportingLabel);
            this.selected = this.filterSelectedByCampaign(this.selected, adGroupList);
            this.setLabel();
        }
    }

    private filterSelectedByCampaign(selected: string[], adGroupList: any): string[] {
        return selected.filter((selectedId: string) =>
            adGroupList.find((adGroup: any) => adGroup.id === selectedId)
        );
    }
}
