import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UINotificationService } from '@bannerflow/ui';
import { AppConfig } from '@config/app.config';
import { SessionService } from '@core/services/internal/session.service';
import { EMPTY, Observable, catchError, map, of, tap } from 'rxjs';

import {
    CreateCreativeSetRequest,
    CreativeSetListItem,
    CreativeSetTableData,
    FiltersRequest,
    Size,
    Sorting
} from '../models/creative-set-models';
import { EllipsisPipe } from '@shared/pipes/ellipsis.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ListServiceError,
    ListServiceErrorTypes
} from '@analytics/models/list-service-error.model';

@Injectable({
    providedIn: 'root'
})
export class ListService {
    private readonly httpClient = inject(HttpClient);
    private readonly sessionService = inject(SessionService);
    private readonly notificationService = inject(UINotificationService);
    private readonly ellipsisPipe = inject(EllipsisPipe);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly router = inject(Router);

    private readonly listServiceUrl = AppConfig.config.LIST_SERVICE_URL;
    private readonly brandId = this.sessionService.user.brand.id;
    getCreativesetsandFolders(
        page: number,
        pageSize: number,
        folderId: string,
        sort?: Sorting,
        targetBrandId?: string
    ): Observable<CreativeSetTableData> {
        const url = `${this.listServiceUrl}/api/${targetBrandId || this.brandId}/creativesets/GetCreativeSetAndFolders`;
        const requestBody = Object.assign(
            { paging: { pageNumber: page, pageSize: pageSize } },
            folderId === 'root' ? null : { folderId },
            { sort }
        );
        return this.httpClient.post<CreativeSetTableData>(url, requestBody).pipe(
            catchError((errorResponse) => {
                this.getCreativeSetsAndFoldersErrorHandle(errorResponse);
                return of({ totalItemsCount: 0, items: [], path: [] });
            }),
            map((val) => this.createCreativesetTableData(val))
        );
    }
    getFilteredCreativesetAndFolders(
        filters: FiltersRequest,
        page: number,
        pageSize: number,
        sort: Sorting,
        targetBrandId?: string
    ): Observable<CreativeSetTableData> {
        const url = `${this.listServiceUrl}/api/${
            targetBrandId || this.brandId
        }/creativesets/FilterCreativeSetsAndFolders`;
        return this.httpClient
            .post<CreativeSetTableData>(url, {
                paging: {
                    pageNumber: page,
                    pageSize: pageSize
                },
                filters,
                sort
            })
            .pipe(
                catchError((error) => {
                    this.errorNotification(error);
                    return EMPTY;
                }),
                map((val) => this.createCreativesetTableData(val))
            );
    }

    private createCreativeSetListItem(item: CreativeSetListItem): CreativeSetListItem {
        return {
            ...item,
            modifiedAt: new Date(item.modifiedAt).toDateString(),
            createdAt: new Date(item.createdAt).toDateString()
        };
    }
    private createCreativesetTableData(val: CreativeSetTableData): CreativeSetTableData {
        if (val.items) {
            return {
                totalItemsCount: val.totalItemsCount,
                items: val.items.map(
                    (item: CreativeSetListItem): CreativeSetListItem =>
                        this.createCreativeSetListItem(item)
                ),
                path: val.path || []
            };
        } else {
            return { totalItemsCount: 0, items: [], path: [] };
        }
    }

    private errorHandle(errorResponse: HttpErrorResponse): Observable<string> {
        const error = errorResponse.error;
        this.validateErrorFormat(errorResponse);

        if (errorResponse && error && error.errorType === ListServiceErrorTypes.NameExistsError) {
            return of(error.errorType);
        } else {
            this.errorNotification(errorResponse);
        }
    }

    private getCreativeSetsAndFoldersErrorHandle(errorResponse: HttpErrorResponse): void {
        this.validateErrorFormat(errorResponse);

        if (this.isEntityDeletedError(errorResponse)) {
            this.router
                .navigate([], {
                    relativeTo: this.activatedRoute,
                    queryParams: { folder: 'root', page: 1 }
                })
                .then(() =>
                    this.notificationService.open(
                        'The folder you tried to open was deleted. Please use another one.',
                        {
                            type: 'error',
                            placement: 'top',
                            autoCloseDelay: 2500
                        }
                    )
                );
        }
    }

    private isEntityDeletedError(errorResponse: HttpErrorResponse): boolean {
        const error = errorResponse.error as ListServiceError;
        return (
            errorResponse && error && error.errorType === ListServiceErrorTypes.EntityDeletedError
        );
    }

    private validateErrorFormat(errorResponse: HttpErrorResponse) {
        let error = errorResponse.error;
        if (typeof error === 'string') {
            try {
                JSON.parse(error);
            } catch (e) {
                this.errorNotification(errorResponse);
            }
        }
    }

    private errorNotification(errorResponse: HttpErrorResponse): void {
        this.notificationService.open('Something went wrong', {
            type: 'error',
            placement: 'top',
            autoCloseDelay: 2000
        });
        throw errorResponse;
    }

    rename(newName: string, row: CreativeSetListItem): Observable<string> {
        const creativeSetId = row.externalId;
        const id = row.id;

        const renameId = row.type === 'Folder' ? `folder/${id}` : `${creativeSetId}`;
        const renameURL = `${this.listServiceUrl}/api/${this.brandId}/CreativeSets/${renameId}/rename`;

        return this.httpClient
            .post(renameURL, { name: newName }, { responseType: 'text' })
            .pipe(catchError((errorResponse) => this.errorHandle(errorResponse)));
    }

    createNewFolder(
        folderName: string,
        folderId: string,
        targetBrandId?: string
    ): Observable<CreativeSetListItem | string> {
        const requestBody = Object.assign(
            { name: folderName },
            folderId === 'root' ? null : { parentFolderId: folderId }
        );

        const url = `${this.listServiceUrl}/api/${targetBrandId || this.brandId}/creativesets/folders/create`;

        return this.httpClient.post<CreativeSetListItem>(url, requestBody).pipe(
            map((item) => this.createCreativeSetListItem(item)),
            catchError((errorResponse) => this.errorHandle(errorResponse))
        );
    }

    getSizes(): Observable<Size[]> {
        const url = `${this.listServiceUrl}/api/${this.brandId}/CreativeSets/QueryDistinctSizes`;

        return this.httpClient.get<Size[]>(url);
    }

    moveItems(
        creativesetListItemTarget: CreativeSetListItem,
        listViewItemIds: string[]
    ): Observable<HttpResponse<void>> {
        const url = `${this.listServiceUrl}/api/${this.brandId}/CreativeSets/move`;

        const requestBody = Object.assign(
            { listViewItemIds },
            creativesetListItemTarget.id === 'root'
                ? null
                : { targetFolderId: creativesetListItemTarget.id }
        );

        return this.httpClient.post<void>(url, requestBody, { observe: 'response' }).pipe(
            catchError((err) => {
                const errorMessage = `${listViewItemIds.length} item${
                    listViewItemIds.length > 1 ? 's' : ''
                } failed to be moved to ${this.ellipsisPipe.transform(creativesetListItemTarget.name)}`;
                this.notificationService.open(errorMessage, {
                    type: 'error',
                    icon: 'close',
                    placement: 'top',
                    autoCloseDelay: 5000,
                    width: 33
                });
                throw err;
            }),
            tap(() => {
                const sucessMessage = `${listViewItemIds.length} item${
                    listViewItemIds.length > 1 ? 's' : ''
                } was successfully moved to ${this.ellipsisPipe.transform(creativesetListItemTarget.name)}`;

                this.notificationService.open(sucessMessage, {
                    type: 'success',
                    placement: 'top',
                    autoCloseDelay: 3000
                });
            })
        );
    }

    deleteItems(
        listViewItemIds: string[],
        actionCorrelationId: string
    ): Observable<HttpResponse<void>> {
        const url = `${this.listServiceUrl}/api/${this.brandId}/CreativeSets/delete`;

        return this.httpClient.post<void>(
            url,
            { ids: listViewItemIds },
            { observe: 'response', headers: { actionCorrelationId } }
        );
    }

    createCreativeset(
        actionCorrelationId: string,
        createCreativeSetRequest: CreateCreativeSetRequest
    ): Observable<string> {
        const url = `${this.listServiceUrl}/api/${this.brandId}/CreativeSets/create`;

        return this.httpClient
            .post<string>(url, createCreativeSetRequest, {
                headers: new HttpHeaders().set('actionCorrelationId', actionCorrelationId)
            })
            .pipe(catchError((errorResponse) => this.errorHandle(errorResponse)));
    }

    getFolderIdByCreativeSetId(creativeSetId: string): Observable<string> {
        const url = `${this.listServiceUrl}/api/${this.brandId}/creativesets/external/${creativeSetId}`;

        return this.httpClient
            .get<{ parentFolderId: string }>(url)
            .pipe(map((response) => response.parentFolderId));
    }
}
