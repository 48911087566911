import { Component, Input } from '@angular/core';
import { ObjectDropDownComponent } from '../objectDropDown.component';
import { NgClass } from '@angular/common';
import { BFDropDownComponent } from '../../dropDown/bfDropDown.component';
import { VersionPickerComponent } from './version.picker.component';

@Component({
    selector: 'versionDropDown',
    templateUrl: 'version.dropdown.component.html',
    styleUrls: ['../objectDropDown.component.scss'],
    imports: [NgClass, BFDropDownComponent, VersionPickerComponent]
})
export class VersionDropDownComponent extends ObjectDropDownComponent {
    @Input() public placeholder = 'No version selected';
    @Input() public bannerSetId: string;
}
