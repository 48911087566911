import { Serializable } from '@shared/interfaces/serializable.interface';
import { Size } from './size.model';

export class SizeFormat implements Serializable<SizeFormat> {
    public id: string;
    public name: string;
    public size: Size;

    constructor(json?: any) {
        this.deserialize(json);
    }

    public deserialize(json: any): this {
        if (!json) {
            return this;
        }

        this.id = json.id;
        this.name = json.name;

        this.size = new Size().deserialize(json.size);

        return this;
    }
}

export class SizeformatDto {
    public name: string;
    public width: number;
    public height: number;

    public serialize(sizeFormat: SizeFormat): SizeformatDto {
        const dto: SizeformatDto = new SizeformatDto();

        dto.name = sizeFormat.name;
        dto.width = sizeFormat.size.width;
        dto.height = sizeFormat.size.height;

        return dto;
    }
}
