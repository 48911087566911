import { Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { AnalyticsBusService } from '@analytics/services/analyticsBusService.service';
import { Subscription } from 'rxjs';
import { ObjectPickerComponent } from './objectPicker.component';

@Component({
    selector: 'objectDropDown',
    template: '',
})
export class ObjectDropDownComponent {
    private readonly resetFilterService = inject(AnalyticsBusService);
    @Input() public multi: boolean;
    @Input() public selected: any;
    @Input() public placeholder = 'No banner set selected';
    @Input() public allowNoneSelection: boolean;
    @Output() private readonly selectedChange: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('objectPicker') private readonly objectPicker: ObjectPickerComponent;
    public label: string;
    public isOpen: boolean;
    public resetFilterSubscribtion$: Subscription;
    protected isBindingId: boolean;
    
    public change(value: any): void {
        this.selectedChange.emit(value);
        this.setLabel();
        // Close after value has changed if not multiselect
        if (!this.multi) {
            this.isOpen = false;
        }
    }
    protected setLabel(): void {
        if (this.multi) {
            if (this.selected?.length) {
                this.label = `${this.selected.length} selected`;
            } else {
                this.label = this.placeholder;
            }
        } else if (this.selected) {
            this.label = this.selected.name;
        } else {
            this.label = this.placeholder;
        }
    }

    public clear(event: any): void {
        event.stopPropagation();
        event.preventDefault();

        // If dropdown is open, let object picker handle clear action
        if (this.isOpen) {
            this.objectPicker.clear();
        } else {
            this.selected = [];
            this.selectedChange.emit(this.selected);
            this.setLabel();
        }
    }

    public onOpen(): void {
        this.isOpen = true;
    }

    public onClose(): void {
        this.isOpen = false;
    }

    public ngOnInit(): void {
        this.setLabel();
        this.resetFilterSubscribtion$ = this.resetFilterService?.resetFilterListener.subscribe(
            (value: any) => {
                if (value) {
                    this.label = this.placeholder;
                }
            }
        );
    }

    public ngOnDestroy() {
        this.resetFilterSubscribtion$?.unsubscribe();
    }
}
