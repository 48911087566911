<span class="control-label"
    >{{ label }}
    <ng-content></ng-content>
</span>
<ui-selectable-list [(selected)]="selected" (change)="updateSelected()">
    @for (option of options; track option) {
<ui-radio
        id="interaction-select-radio-list-item"
       
        [label]="option.text"
        [value]="option.value"
        [disabled]="option.disabled"
    >
    </ui-radio>
}
</ui-selectable-list>
