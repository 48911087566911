import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'toTimeAgo',
})
export class ToTimeAgoPipe implements PipeTransform {
    public transform(date: moment.MomentInput): string {
        return moment(date).fromNow();
    }
}

