import { Injectable, inject } from '@angular/core';
import { Version } from '@shared/models/version/version.model';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class VersionService {
    private readonly apiService = inject(ApiService);
    public getVersions(bannerSetId: string): Promise<Version[]> {
        // Fetch brands and store result in brand promise for later use
        return this.apiService.get(`bannersets/${bannerSetId}/versions`).then((data: any) => {
            const versions = data.map((version: any) => new Version().deserialize(version));
            return Promise.resolve(versions);
        });
    }
}
