import { Component, ElementRef, Renderer2, inject } from '@angular/core';
import { SizeFormatService } from '@core/services/api/bannerflow/sizeformat.service';
import { ExObjectItem } from '@shared/classes/ExObjectQuery';
import { ObjectType } from '@shared/enums/objectType.enum';
import { SizeFormat } from '@shared/models/banner/sizeFormat.model';
import { ObjectPickerComponent } from '../objectPicker.component';
import { FormsModule } from '@angular/forms';
import { DebounceInputDirective } from '../../../directives/debounceInput.directive';

import { UIModule } from '@bannerflow/ui';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
    selector: 'sizeFormatPicker',
    templateUrl: 'sizeformat.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    imports: [FormsModule, DebounceInputDirective, UIModule, NgxDatatableModule]
})
export class SizeFormatPickerComponent extends ObjectPickerComponent {
    private readonly sizeFormatService = inject(SizeFormatService);
    public readonly elementRef = inject(ElementRef);
    public readonly renderer = inject(Renderer2);
    public type: ObjectType = ObjectType.SizeFormat;
    public tableRows: (SizeFormat | ExObjectItem<SizeFormat>)[] = [];

    protected loadItems(): void {
        this.loading = true;

        this.sizeFormatService.getSizeFormats().then((sizeFormats: SizeFormat[]) => {
            this.tableRows = sizeFormats;
            super.loadItems();
            this.datatable.recalculate();
            this.loading = false;
        });
    }
}
