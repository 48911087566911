import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
    public static capitalize(value: string = '', forceLowerCase?: boolean): string {
        if (value) {
            if (forceLowerCase) {
                value = value.toLowerCase();
            }
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        return value;
    }
    public transform(value: string, forceLowerCase?: boolean): string {
        return CapitalizePipe.capitalize(value, forceLowerCase);
    }
}
