import { ICreativeRef } from '../api/campaign.interface';
import { Model } from '../lib/model';

export class CreativeRef extends Model<CreativeRef> implements ICreativeRef {
    public creativeId: string;
    public creativeSetId: string;

    public deserialize(json: any): this {
        if (!json) {
            return this;
        }

        this.creativeId = json.creativeId;
        this.creativeSetId = json.creativeSetId;

        return this;
    }
}
