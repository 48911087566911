import { PublishAttemptStatus } from '../api/campaign-status.enum';
import { IPublishAttempt } from '../api/campaign.interface';
import { Model } from '../lib/model';
import { PublishDestination } from './publish-destination.model';
import { PublishUser } from './publish-user.model';
import { PublishedAdRef } from './published-ad-ref.model';

export class PublishAttempt extends Model<PublishAttempt> implements IPublishAttempt {
    public destination: PublishDestination;
    public correlationId: string;
    public configurationId?: string;
    public tagPublish: boolean;
    public created?: string;
    public completedAt: string;
    public status: PublishAttemptStatus;
    public user?: PublishUser;
    public ads?: PublishedAdRef[];
    public hasReportingLabel?: boolean;

    public deserialize(json: any): this {
        if (!json) {
            return this;
        }

        this.destination = new PublishDestination().deserialize(json.destination);
        this.correlationId = json.correlationId;
        this.configurationId = json.configurationId || undefined;
        this.tagPublish = json.tagPublish;
        this.created = json.created || undefined;
        this.completedAt = json.completedAt;
        this.status = json.status;
        this.user = new PublishUser().deserialize(json.user);
        this.ads = json.ads ? json.ads.map((ad: any) => new PublishedAdRef().deserialize(ad)) : [];
        this.hasReportingLabel = json.hasReportingLabel;

        return this;
    }

    public deserializeForList(json: any): this {
        if (!json) {
            return this;
        }

        this.destination = new PublishDestination().deserialize(json.destination);
        this.correlationId = json.correlationId;
        this.tagPublish = json.tagPublish;
        this.completedAt = json.completedAt;
        this.status = json.status;

        return this;
    }
}
