import { Component, Input } from '@angular/core';
import { ObjectDropDownComponent } from '../objectDropDown.component';
import { NgClass } from '@angular/common';
import { BFDropDownComponent } from '../../dropDown/bfDropDown.component';
import { BannerSetPickerComponent } from './bannerset.picker.component';

@Component({
    selector: 'bannerSetDropDown',
    templateUrl: 'bannerset.dropdown.component.html',
    styleUrls: ['../objectDropDown.component.scss'],
    imports: [NgClass, BFDropDownComponent, BannerSetPickerComponent]
})
export class BannerSetDropDownComponent extends ObjectDropDownComponent {
    @Input() public onlyStudio = false;
    @Input() public placeholder = 'No banner set selected';
}
