import { Component, ElementRef, Input, Renderer2, inject } from '@angular/core';
import { ExObjectItem } from '@shared/classes/ExObjectQuery';
import { ObjectType } from '@shared/enums/objectType.enum';
import { IAdGroup } from '@shared/models/campaign/api/campaign.interface';
import { ObjectPickerComponent } from '../objectPicker.component';
import { AnalyticsApiService } from '@analytics/services/analyticsApi.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'adGroupPicker',
    templateUrl: 'adgroup.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    imports: [NgxDatatableModule, UIModule]
})
export class AdGroupPickerComponent extends ObjectPickerComponent {
    private readonly analyticsApiService = inject(AnalyticsApiService);
    public readonly elementRef = inject(ElementRef);
    public readonly renderer = inject(Renderer2);
    public type: ObjectType = ObjectType.StudioCampaign;
    public tableRows: (ExObjectItem<IAdGroup> | IAdGroup)[] = [];
    @Input() public campaignID: string;

    protected async loadItems(): Promise<void> {
        this.loading = true;
        const compaign = await this.analyticsApiService.getCampaign(this.campaignID);
        this.tableRows = compaign.adGroups;
        super.loadItems();
        this.datatable.recalculate();
        this.loading = false;
    }
}
