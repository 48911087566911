import { EventEmitter, ElementRef, OnInit, Directive, Input, Output, inject } from '@angular/core';
import { NgModel } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Directive({
    selector: '[debounceInput]',
})
export class DebounceInputDirective implements OnInit {
    private readonly elementRef = inject(ElementRef);
    private readonly model = inject(NgModel);

    @Input('debounceInputDelay') public delay = 500;
    @Output('debounceInputChange') public change: EventEmitter<any> = new EventEmitter();

    public ngOnInit(): void {
        const eventStream = fromEvent(this.elementRef.nativeElement, 'keyup').pipe(
            map(() => this.model.value),
            debounceTime(this.delay)
        );
        eventStream.subscribe((input) => this.change.emit(input));
    }
}
