import { Injectable, inject } from '@angular/core';
import { SessionService } from '@core/services/internal/session.service';
import { ExObjectItem, ExObjectQuery, ExObjectResult } from '@shared/classes/ExObjectQuery';
import { BannerSet } from '@shared/models/banner/bannerSet.model';
import { ExCreativeSet } from '@shared/models/campaign/models/ex-creative-set.model';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class BannerSetService {
    private readonly apiService = inject(ApiService);
    private readonly sessionService = inject(SessionService);
    public async getCreativeSetsWithFolders(query: ExObjectQuery): Promise<ExCreativeSet[]> {
        const apiResponse: ExObjectResult<BannerSet> = await this.getBannerSetsEx(query);
        const response: ExCreativeSet[] = apiResponse.items.map((item: ExObjectItem<BannerSet>) =>
            new ExCreativeSet().deserialize(item)
        );
        return response;
    }

    public getBannerSet(slug: string = ''): Promise<BannerSet> {
        const promise = new Promise<BannerSet>((resolve) => {
            this.apiService
                .get(`/api/v1/b/[accountSlug]/[brandSlug]/lightweightbannersets/${slug}`, {
                    anonymous: true
                })
                .then((data: any) => {
                    resolve(new BannerSet().deserialize(data));
                });
        });

        return promise;
    }

    public getBannerSets(): Promise<BannerSet[]> {
        const promise = new Promise<BannerSet[]>((resolve) => {
            this.apiService.get('bannerset/all').then((data: any) => {
                resolve(
                    data.map((bannerSet: any) => new BannerSet().deserialize(bannerSet))
                );
            });
        });

        return promise;
    }

    public getBannerSetsEx(
        query: ExObjectQuery,
        includeCreativeSets: boolean = true
    ): Promise<ExObjectResult<BannerSet>> {
        const promise = new Promise<any>((resolve, reject) => {
            this.apiService
                .post(
                    `/api/v1/b/[accountSlug]/${query.brand || this.sessionService.user.brand.id}/meta/bannersets${
                        includeCreativeSets ? '' : '?includeCreativeSets=false'
                    }`,
                    query,
                    { anonymous: true }
                )
                .then((data: any) => {
                    if (data) {
                        const result: ExObjectResult<BannerSet> = new ExObjectResult<BannerSet>();
                        const items = data.data.content.map((item: any) => {
                            const bannerSet = new BannerSet().deserialize(item);

                            const exItem: ExObjectItem<BannerSet> = new ExObjectItem<BannerSet>();
                            exItem.data = bannerSet;
                            exItem.isFolder = item.isFolder;

                            return exItem;
                        });
                        result.items = items;
                        result.breadcrumbs = data.data.meta.breadCrumbs || [];
                        result.hasMore = data.data.hasMore;

                        if (data.data.meta.id) {
                            result.breadcrumbs.unshift({ name: null, id: null });
                        }
                        resolve(result);
                    } else {
                        reject(new Error('Failed to fetch banner sets'));
                    }
                });
        });
        return promise;
    }
    public getBannerSetsByBrand(brandSlug: string): Promise<BannerSet[]> {
        const promise: Promise<BannerSet[]> = new Promise<BannerSet[]>((resolve) => {
            this.apiService
                .get(`/api/v1/b/[accountSlug]/${brandSlug}/lightweightbannersets`, {
                    anonymous: true
                })
                .then((data: any) => {
                    resolve(
                        data.map((bannerSet: any) => new BannerSet().deserialize(bannerSet))
                    );
                });
        });
        return promise;
    }
}
