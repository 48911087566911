import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    public cache: any = {};

    public add(key: string, data: any, ttl: number = 0): void {
        try {
            data = JSON.parse(JSON.stringify(data));
        } catch (e) {
            console.error(`Error cloning data for key "${key}":`, e);
        }
        this.cache[key] = data; // Save clone to make sure changes to original data won't be saved
    }

    public get(key: string): any {
        return this.cache[key] ? JSON.parse(JSON.stringify(this.cache[key])) : undefined; // Return a clone to avoid modifying the source
    }
}
