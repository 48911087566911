
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { PullRequestEnvService } from '@core/services/api/azure/environment.service';

@Component({
    selector: 'environment-picker',
    template: `
        <div class="selector">
            <ui-select
                [selected]="currentBranch()"
                width=""
                (selectedChange)="redirectToBranch($event)"
            >
                <ui-option value="main">Main</ui-option>
                @for (branch of branches(); track branch) {
<ui-option [value]="branch">{{
                    branch
                }}</ui-option>
}
            </ui-select>
        </div>
    `,
    imports: [UIModule]
})
export class EnvironmentPickerComponent implements OnInit {
    private readonly prEnvService = inject(PullRequestEnvService);
    private readonly route = inject(ActivatedRoute);
    public currentBranch = this.prEnvService.currentBranchSignal;
    public branches = this.prEnvService.branchesSignal;

    ngOnInit() {
        const branch = this.route.snapshot.queryParamMap.get('branch');
        if (branch) {
            this.prEnvService.setBranch(branch);
        }
    }
    public redirectToBranch(branchName: string) {
        this.prEnvService.redirectToBranch(branchName);
    }
}
