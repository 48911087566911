import { Injectable, OnDestroy, inject } from '@angular/core';
import { IUIListDataNode, UIListDataSource, UINotificationService } from '@bannerflow/ui';
import { HotkeyService } from '@core/services/internal/hotkey.service';
import { SessionService } from '@core/services/internal/session.service';
import {
    ICampaignFolder,
    ICampaignListItem,
    IFolderId,
    ISharedCampaignsFoldersAll
} from '@shared/models/campaign/api/campaign.interface';
import { Campaign } from '@shared/models/campaign/models/campaign.model';
import {
    CampaignType,
    IPreviousCampaignListItemLocation,
    ISharedCampaignFolder,
    ISharedCampaignListItem,
    ISharedFolderId
} from '@shared/models/shared-campaign-list/shared-campaign-list';
import { SharedCampaignUIListDataNode } from '@shared/models/shared-campaign-list/shared-campaign-list-ui';
import { ISocialCampaign } from '@shared/models/social-campaign/social-campaign.model';
import { Subscription } from 'rxjs/internal/Subscription';
import { tap } from 'rxjs/operators';
import { CampaignApiService } from '../campaign/campaign-api.service';
import { SharedListWorkerService } from './shared-list-worker.service';
import { Platform } from '@angular/cdk/platform';


@Injectable({ providedIn: 'root' })
export class SharedCampaignListService implements OnDestroy {
    private readonly campaignApiService = inject(CampaignApiService);
    private readonly sessionService = inject(SessionService);
    private readonly sharedListWorkerService = inject(SharedListWorkerService);
    private readonly uiNotificationService = inject(UINotificationService);
    private readonly hotkeyService = inject(HotkeyService);
    private readonly platform = inject(Platform);
    public campaignListDataSource: UIListDataSource = new UIListDataSource();
    public deletedCampaigns: string[] = [];
    public deletedFolders: string[] = [];
    private readonly campaignListCache: Map<string, SharedCampaignUIListDataNode[]> = new Map();
    private lastMove: IPreviousCampaignListItemLocation;
    private hotkeySub: Subscription;
    // @ts-ignore
    private readonly _hotkeyInit = this.setupHotkeys();
    
    public ngOnDestroy(): void {
        this.hotkeySub.unsubscribe();
    }
    public clearData(): void {
        this.campaignListCache.clear();
        this.campaignListDataSource = new UIListDataSource();
    }
    public addToDeletedCampaigns(itemId: string): void {
        this.deletedCampaigns.push(itemId);
    }
    public addToDeletedFolders(folder: ISharedFolderId): void {
        this.deletedFolders.push(folder.self);
    }
    public checkHasSocialCampaignManager(): boolean {
        return this.sessionService.hasFeature(SessionService.FEATURES.SOCIALCAMPAIGNMANAGER);
    }
    public checkHasDisplayCampaignManager(): boolean {
        return this.sessionService.hasFeature(SessionService.FEATURES.CAMPAIGNMANAGER);
    }
    public async getCampaignFolders(brandSlug?: string): Promise<SharedCampaignUIListDataNode[]> {
        const folders: ICampaignFolder[] = await this.campaignApiService.getFolders(
            undefined,
            brandSlug
        );
        return SharedCampaignUIListDataNode.createFromFolders(folders);
    }
    public async getAllCampaigns(brandSlug?: string): Promise<ISharedCampaignListItem[]> {
        const allCampaigns: ISharedCampaignsFoldersAll =
            await this.campaignApiService.getAllCampaigns(brandSlug);
        return allCampaigns.campaigns;
    }
    public async getFilteredCampaigns(filter: string): Promise<SharedCampaignUIListDataNode[]> {
        const getDisplayCampaignsPromises: any = () => this.getFilteredDisplayCampaigns(filter);
        const getSocialCampaignsPromises: any = () => Promise.resolve([]);
        return this.sharedListWorkerService.mapCampaigns(
            getDisplayCampaignsPromises,
            getSocialCampaignsPromises,
            this.checkHasDisplayCampaignManager(),
            this.checkHasSocialCampaignManager()
        );
    }
    public async getCampaignListByFolderId(
        folderId?: ISharedFolderId,
        brandSlug?: string
    ): Promise<SharedCampaignUIListDataNode<ISharedCampaignListItem>[]> {
        const getDisplayCampaignsPromises: any = () => this.getDisplayCampaignsByFolderId(folderId, brandSlug);
        const getSocialCampaignsPromises: any = () => Promise.resolve([]);
        return this.sharedListWorkerService.mapCampaigns(
            getDisplayCampaignsPromises,
            getSocialCampaignsPromises,
            this.checkHasDisplayCampaignManager(),
            this.checkHasSocialCampaignManager()
        );
    }
    private getFilteredDisplayCampaigns(filter: string): Promise<ICampaignListItem[]> {
        return this.campaignApiService
          .getFilteredCampaigns(filter)
          .catch((error): ICampaignListItem[] => {
            console.error('⚠️ Failed to fetch filtered campaigns, returning empty list ✨', error);
            return [];
          });
      }
    public async loadCampaignsToNode(
        node?: IUIListDataNode,
    ): Promise<void> {
        const root = 'root';
        if (!node) {
            if (!this.campaignListCache.has(root)) {
                // root
                const campaigns: SharedCampaignUIListDataNode<ISharedCampaignListItem>[] =
                    await this.getCampaignListByFolderId();
                this.campaignListDataSource.insert(campaigns);
                this.campaignListCache.set(
                    root,
                    this.campaignListDataSource.data as SharedCampaignUIListDataNode[]
                );
            } else if (this.campaignListCache.has(root)) {
                this.campaignListDataSource.data = this.campaignListCache.get('root');
            }
            if (this.lastMove?.node && !this.lastMove.unDone) {
                const groupedById = this.groupDataBy(this.campaignListDataSource.data, 'id');
                if (groupedById[this.lastMove.node.data.id]?.length > 1) {
                    this.campaignListDataSource.data.splice(
                        this.campaignListDataSource.data.indexOf(this.lastMove.node) + 1,
                        1
                    );
                    this.campaignListDataSource.emit();
                }
            }
        } else if (!this.campaignListCache.has(node.data?.folderId.self)) {
            const campaigns: SharedCampaignUIListDataNode<ISharedCampaignListItem>[] =
                await this.getCampaignListByFolderId(node.data?.folderId);
            this.campaignListDataSource.insert(campaigns, node);
            this.campaignListDataSource.update(node, { isLoading: false, totalCount: 0 });
            if (this.lastMove?.node) {
                const groupedById = this.groupDataBy(node.children, 'id');
                if (groupedById[this.lastMove.node.data.id]?.length > 1) {
                    node.children.splice(node.children.indexOf(this.lastMove.node) + 1, 1);
                    this.campaignListDataSource.emit();
                }
            }
            this.campaignListCache.set(node?.data?.folderId.self, campaigns);
        }
    }
    private groupDataBy(data: any, key: string): any {
        return data.reduce((storage: any, item: any) => {
            const group = item.data[key];
            if (group !== undefined) {
                storage[group] = storage[group] || [];
            }
            storage[group]?.push(item);
            return storage;
        }, {});
    }

    public async createFolder(
        folderName: string,
        folderId?: ISharedFolderId
    ): Promise<SharedCampaignUIListDataNode> {
        let folder: ISharedCampaignFolder;
        if (folderId === undefined) {
            folder = await this.campaignApiService.createRootFolder(folderName);
        } else {
            folder = await this.campaignApiService.createSubfolder(folderName, folderId);
        }
        const createdFolder: SharedCampaignUIListDataNode =
            SharedCampaignUIListDataNode.createFromFolder(folder);

        const destinationFolder: IUIListDataNode =
            folderId === undefined
                ? this.campaignListDataSource.rootNode
                : this.campaignListDataSource.find(
                      (x: IUIListDataNode) => x.data?.folderId?.self === folderId.self
                  );
        this.campaignListDataSource.insert(createdFolder, destinationFolder);
        return createdFolder;
    }
    public deleteFolder(folderId: ISharedFolderId): Promise<boolean> {
        this.addToDeletedFolders(folderId);
        return this.campaignApiService.deleteFolder(folderId);
    }
    public async renameDisplayCampaign(
        campaign: Campaign,
        campaignName: string,
        campaignFolderId?: ISharedFolderId
    ): Promise<string> {
        const updatedCampaignData: Campaign = await this.campaignApiService.renameCampaign(
            campaign.id,
            campaignFolderId,
            campaignName
        );
        return updatedCampaignData.name;
    }

    public async createDisplayCampaign(
        campaignName: string,
        folderId?: IFolderId
    ): Promise<Campaign> {
        const newCreatedCampaign = await this.campaignApiService.createCampaign(
            campaignName,
            folderId
        );
        return this.mapDisplayCampaign(newCreatedCampaign);
    }

    public async moveFolderOrCampaign(
        selectedNode: SharedCampaignUIListDataNode,
        destination: ISharedFolderId,
        isUndoing?: boolean
    ): Promise<void> {
        const parentFolder: IUIListDataNode = this.campaignListDataSource.getParent(selectedNode);
        const source: ISharedFolderId = parentFolder?.data?.folderId;

        if (source?.self === destination?.self && !isUndoing) {
            return;
        }
        if (SharedCampaignUIListDataNode.isCampaignFolder(selectedNode.data)) {
            if (selectedNode?.data.folderId.self === destination?.self) {
                return;
            }
            selectedNode.data = await this.campaignApiService.moveFolder(
                selectedNode.data.folderId,
                destination
            );
        }
        else {
            if (selectedNode.data.campaignType === CampaignType.Social) {
                this.uiNotificationService.open(
                    'Moving a social campaign into a folder is not allowed.',
                    {
                        type: 'error',
                        placement: 'top',
                        autoCloseDelay: 3000
                    }
                );
                return;
            }
            const updatedCampaignData: Campaign = await this.campaignApiService.moveCampaign(
                selectedNode.data.id,
                source,
                destination
            );
            selectedNode.name = updatedCampaignData.name;
        }

        const destinationNode: IUIListDataNode = this.campaignListDataSource.find(
            (folder: IUIListDataNode) =>
                SharedCampaignUIListDataNode.isCampaignFolder(folder.data) &&
                folder.data.folderId?.self === destination?.self
        );
        if (destinationNode && !SharedCampaignUIListDataNode.isCampaignFolder(selectedNode.data)) {
            this.campaignListDataSource.remove(selectedNode, true);
            this.campaignListDataSource.insert(selectedNode, destinationNode);
        } else {
            this.campaignListDataSource.move(selectedNode, destinationNode);
            this.campaignListDataSource.emit(selectedNode); // list don't update its view without emit!
        }
        this.lastMove = { node: selectedNode, source, destination, unDone: isUndoing };
        if (!isUndoing) {
            this.uiNotificationService.open(
                `<span class="notification-ellipsis">${
                    selectedNode.name
                }</span> has been moved to <span class="notification-ellipsis">${
                    destinationNode ? destinationNode.name : 'root folder'
                }</span> <br> Press ${this.platform.IOS ? '⌘+Z to undo.' : 'CTRL+Z to undo.'}`,
                { autoCloseDelay: 5000, placement: 'top' }
            );
        }
    }

    private undoMove(): void {
        if (
            !this.lastMove?.unDone &&
            !!this.lastMove?.node &&
            this.campaignNotRemoved() &&
            this.folderNotRemoved()
        ) {
            this.moveFolderOrCampaign(this.lastMove.node, this.lastMove.source, true);
            this.campaignListDataSource.sort('-modified');
        }
    }
    private campaignNotRemoved(): boolean {
        return !!this.deletedCampaigns.indexOf(this.lastMove.node.data.id);
    }
    private folderNotRemoved(): boolean {
        return !!this.deletedFolders.indexOf(this.lastMove.node.data.folderId?.self);
    }

    private setupHotkeys(): void {
        this.hotkeySub = this.hotkeyService
            .addShortcut('control.z')
            .pipe(tap(() => this.undoMove()))
            .subscribe();
        this.hotkeySub = this.hotkeyService
            .addShortcut('meta.z')
            .pipe(tap(() => this.undoMove()))
            .subscribe();
    }

    private getFlatCampaignIds(folder: ISharedCampaignFolder): string[] {
        if (!folder.subfoldersList) {
            return [...folder.campaigns];
        }
        return [...folder.campaigns].concat(
            ...folder.subfoldersList.map((subFolder) => this.getFlatCampaignIds(subFolder))
        );
    }

    private getDisplayCampaignsByFolderId(
        folderId?: ISharedFolderId,
        brandSlug?: string
      ): Promise<ICampaignListItem[]> {
        return this.checkHasDisplayCampaignManager()
          ? this.campaignApiService
              .getCampaignsByFolderId(folderId, brandSlug)
              .then((result) => result.campaigns)
              .catch((error): ICampaignListItem[] => {
                console.error('🚨 Error fetching campaigns by folder ID, returning empty array 🤷‍♀️', error);
                return [];
              })
          : Promise.resolve([]);
      }

    private mapSocialCampaign(socialCampaign: ISocialCampaign): Campaign {
        const campaign: Campaign = new Campaign();
        campaign.campaignType = CampaignType.Social;
        return campaign.deserialize(socialCampaign);
    }

    private mapDisplayCampaign(displayCampaign: Campaign): Campaign {
        displayCampaign.campaignType = CampaignType.Display;
        return displayCampaign;
    }

    public navigateToCM(
        campaignId: string,
        campaignType: CampaignType,
        currentFolderId?: IFolderId,
        adListView?: boolean
    ): void {
        const accountSlug: string = this.sessionService.user.account.slug;
        const brandSlug: string = this.sessionService.user.brand.slug;
        const externalNavUrl: string = this.sharedListWorkerService.getNavigationUrlToCM(
            campaignType,
            accountSlug,
            brandSlug,
            campaignId,
            currentFolderId,
            adListView
        );
        window.open(externalNavUrl, '_blank');
    }
}
