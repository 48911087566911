import { Component, Input, inject } from '@angular/core';
import { BrandService } from '@core/services/api/bannerflow/brand.service';
import { Brand } from '@shared/models/brand.model';
import { ObjectDropDownComponent } from '../objectDropDown.component';
import { NgClass } from '@angular/common';
import { BFDropDownComponent } from '../../dropDown/bfDropDown.component';
import { BrandPickerComponent } from './brand.picker.component';

@Component({
    selector: 'brandDropDown',
    templateUrl: 'brand.dropdown.component.html',
    styleUrls: ['../objectDropDown.component.scss'],
    imports: [NgClass, BFDropDownComponent, BrandPickerComponent]
})
export class BrandDropDownComponent extends ObjectDropDownComponent {
    private readonly brandService = inject(BrandService);

    @Input() public placeholder = 'No brand selected';

    public async ngOnInit(): Promise<void> {
        if (Array.isArray(this.selected) && this.selected.length > 0) {
            const brands: Brand[] = await this.brandService.getBrands();
            this.selected = this.filterSelectedByBrands(this.selected, brands);
            this.setLabel();
        }
    }
    private filterSelectedByBrands(selected: string[], brands: Brand[]): string[] {
        return selected.filter((selectedId: string) =>
            brands.find((brand) => brand.id === selectedId)
        );
    }
}
