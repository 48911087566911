<div class="objectPicker">
    <div class="objectPicker__search">
        <input
            type="text"
            placeholder="Search country"
            debounceInput
            (debounceInputChange)="search()"
            [(ngModel)]="searchQuery"
        />
    </div>
    <div class="objectPicker__table">
        @if (initialLoad && loading) {
<ui-loader></ui-loader>
}
        <ngx-datatable
            #datatable
            [rows]="tableRows"
            [columnMode]="'force'"
            [rowHeight]="40"
            (activate)="onTableClick($event)"
            [selected]="selectedObjects"
            (selectedChange)="selectedObjects = $event"
            [selectionType]="multi ? SelectionType.multiClick : SelectionType.single"
            [selectCheck]="canSelect"
        >
            @if (multi) {
<ngx-datatable-column
               
                [width]="30"
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
                [headerCheckboxable]="true"
                [checkboxable]="true"
            ></ngx-datatable-column>
}
            <ngx-datatable-column name="Name" prop="name">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{ value }}
                    </span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
