<div
    class="welcomePanel"
    [ngClass]="{ 'welcomePanel--visible': visible, 'with-sidebar': isSidebarExpanded | async }"
>
    <div class="welcomePanel__content">
        <div class="welcomePanel__skip">
            <ui-checkbox
                id="interaction-checkbox-hide-welcome-panel"
                [(selected)]="showOnLogin"
                (selectedChange)="showOnLoginChange()"
            ></ui-checkbox>
            Hide this on login
        </div>
        <div class="welcomePanel__text">
            <div class="welcomePanel__textHeader">Welcome {{ sessionService.user.firstName }}!</div>
            @if (sessionService.user.brand.impressionsLastWeek > 0) {
<div
                class="welcomePanel__textBody"
               
            >
                <p>
                    The last seven days your brand has had a total of<br />
                    <strong>{{ sessionService.user.brand.impressionsLastWeek | number }}</strong>
                    new impressions.
                </p>
                <p>Cheers,<br />Bannerflow <span class="welcomePanel__textButterfly"></span></p>
            </div>
}
            @if (sessionService.user.brand.impressionsLastWeek === 0) {
<div
                class="welcomePanel__textBody"
               
            >
                <p>Try publish some of your banners, it's fun. We promise.</p>
                <p>Cheers,<br />Bannerflow <span class="welcomePanel__textButterfly"></span></p>
            </div>
}
        </div>
        <div class="welcomePanel__latestObjects">
            <div class="welcomePanel__latestObjectsHeader">Recently viewed</div>
            <div class="welcomePanel__latestObjectsList">
                @for (object of visitedObjects; track object) {
<div
                    class="welcomePanel__latestObjectsItem"
                   
                    (click)="gotoObject(object)"
                    (keydown)="gotoObject(object)"
                >
                    @if (!object.image) {
<div
                        class="welcomePanel__latestObjectsItemIcon  welcomePanel__latestObjectsItemIcon--{{
                            object.type
                        }}"
                       
                    ></div>
}
                    @if (object.image) {
<div
                        class="welcomePanel__latestObjectsItemThumb"
                        [ngStyle]="{ backgroundImage: 'url(' + object.image + ')' }"
                       
                    ></div>
}
                    <div class="welcomePanel__latestObjectsItemTitle">
                        {{ object.name | ellipsis: 50 : true }}
                    </div>
                </div>
}
                @for (placeholder of placeholders; track placeholder) {
<div
                    class="welcomePanel__latestObjectsItem welcomePanel__latestObjectsItem--ghost"
                   
                >
                    <div class="welcomePanel__latestObjectsItemThumb"></div>
                    <div class="welcomePanel__latestObjectsItemTitle">
                        <span>###</span>&nbsp;&nbsp;<span>######</span>
                    </div>
                </div>
}
            </div>
        </div>
    </div>
</div>
