import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UIErrorDialogService } from '@bannerflow/ui';
import { AppConfig } from '@config/app.config';
import { SessionService } from '@core/services/internal/session.service';
import {
    ICampaignFolder,
    ICampaignListItem,
    ICampaignsByFolder,
    IFolderId,
    ISharedCampaignsFoldersAll
} from '@shared/models/campaign/api/campaign.interface';
import { AnalyticsAd } from '@shared/models/campaign/models/ad.model';
import { Campaign } from '@shared/models/campaign/models/campaign.model';

import { IFallbackCreative } from '@analytics/models/creative-set-models';
import { ReportingLabel } from '@analytics/models/reportingLabel.model';
import { AuthService } from '@auth0/auth0-angular';
import { CreativeFailed } from '@shared/models/campaign/models/creativeFailed.model';
import { firstValueFrom, map, Observable } from 'rxjs';
import { ApiService, BFHttpError } from '../api.service';

 // Don't use without a view service to validate models. Only represents campaign api as interface.
 
@Injectable({
    providedIn: 'root'
})
export class CampaignApiService {
    private readonly apiService = inject(ApiService);
    private readonly sessionService = inject(SessionService);
    private readonly errorDialogService = inject(UIErrorDialogService);
    private readonly router = inject(Router);
    private readonly http = inject(HttpClient);
    private readonly authService = inject(AuthService);
    private fallbackCreatives: IFallbackCreative[];

    public getFallbackCreatives(): Observable<boolean> {
        return this.http.get(`${this.getApiPrefix()}/Creative/FallbackCreatives`).pipe(
            map((response) => {
                if (Array.isArray(response)) {
                    this.fallbackCreatives = response;
                }
                return true;
            })
        );
    }
    public getFallbackCreativesForHeatmap(): IFallbackCreative[] {
        return this.fallbackCreatives;
    }

    protected getApiPrefix(brandSlug?: string): string {
        return `${AppConfig.config.CAMPAIGN_SERVICE_URL}/api/${this.sessionService.user.account.slug}/${
            brandSlug || this.sessionService.user.brand.slug
        }`;
    }

    protected async setupHeaders(): Promise<HttpHeaders> {
        const token: string = await firstValueFrom(this.authService.getAccessTokenSilently());
        const headers: HttpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        });

        return headers;
    }

    public async createCampaign(campaignName: string, folderId: IFolderId): Promise<Campaign> {
        try {
            const headers: HttpHeaders = await this.setupHeaders();
            const response: any = await this.apiService.post(
                `${this.getApiPrefix()}/Campaign`,
                { campaignName, folderId },
                {
                    anonymous: true,
                    headers
                }
            );

            return new Campaign().deserialize(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async renameCampaign(
        campaignId: string,
        campaignFolderId: IFolderId,
        campaignName: string
    ): Promise<Campaign> {
        try {
            const headers: HttpHeaders = await this.setupHeaders();
            const response: any = await this.apiService.patch(
                `${this.getApiPrefix()}/Campaign`,
                { campaignId, campaignFolderId, campaignName },
                {
                    anonymous: true,
                    headers
                }
            );

            return new Campaign().deserialize(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async deleteCampaign(campaignId: string): Promise<boolean> {
        try {
            const headers: HttpHeaders = await this.setupHeaders();
            await this.apiService.delete(`${this.getApiPrefix()}/Campaign`, {
                anonymous: true,
                headers,
                queryParameters: { campaignId }
            });

            return true;
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async getCreativePreviewScript(creativeId: string): Promise<string> {
        try {
            const headers: HttpHeaders = await this.setupHeaders();
            const response = await firstValueFrom(
                this.http.get(`${this.getApiPrefix()}/creative/${creativeId}/preview`, {
                    headers,
                    responseType: 'text'
                })
            );
            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }


    public async getAdForAnalytics(adId: string): Promise<AnalyticsAd> {
        try {
            const headers: HttpHeaders = await this.setupHeaders();
            const response: any = await this.apiService.get(
                `${this.getApiPrefix()}/Ad/GetAnalyticsAd`,
                {
                    anonymous: true,
                    headers,
                    queryParameters: { adId }
                }
            );

            return new AnalyticsAd().deserialize(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async createRootFolder(folderName: string): Promise<ICampaignFolder> {
        try {
            const headers: HttpHeaders = await this.setupHeaders();
            const response: any = await this.apiService.post(
                `${this.getApiPrefix()}/Folder/CreateRootFolder`,
                { folderName },
                {
                    anonymous: true,
                    headers
                }
            );

            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async createSubfolder(
        folderName: string,
        parentFolderId: IFolderId
    ): Promise<ICampaignFolder> {
        try {
            const headers: HttpHeaders = await this.setupHeaders();
            const response: any = await this.apiService.post(
                `${this.getApiPrefix()}/Folder/CreateSubFolder`,
                { folderName, parentFolderId },
                { anonymous: true, headers }
            );

            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async moveCampaign(
        campaignId: string,
        source: IFolderId,
        destination: IFolderId
    ): Promise<Campaign> {
        try {
            const headers: HttpHeaders = await this.setupHeaders();
            const response: any = await this.apiService.post(
                `${this.getApiPrefix()}/Folder/MoveCampaign`,
                { campaignId, source, destination },
                {
                    anonymous: true,
                    headers
                }
            );

            return new Campaign().deserialize(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async moveFolder(source: IFolderId, destination: IFolderId): Promise<ICampaignFolder> {
        const headers: HttpHeaders = await this.setupHeaders();
        try {
            const response: any = await this.apiService.post(
                `${this.getApiPrefix()}/Folder/MoveFolder`,
                { source, destination },
                {
                    anonymous: true,
                    headers
                }
            );

            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }
    public async renameFolder(folderId: IFolderId, name: string): Promise<ICampaignFolder> {
        const headers: HttpHeaders = await this.setupHeaders();
        try {
            const response: any = await this.apiService.post(
                `${this.getApiPrefix()}/Folder/RenameFolder`,
                { folderId, name },
                {
                    anonymous: true,
                    headers
                }
            );

            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async getCampaignsByFolderId(
        folderId?: IFolderId,
        brandSlug?: string
    ): Promise<ICampaignsByFolder> {
        const selfFolderId: string = folderId?.self || 'root';
        const queryParameters: IFolderId = folderId;
        const headers: HttpHeaders = await this.setupHeaders();
        try {
            const response: any = await this.apiService.get(
                `${this.getApiPrefix(brandSlug)}/Folder/${selfFolderId}/Campaigns`,
                {
                    anonymous: true,
                    headers,
                    queryParameters
                }
            );

            if (!response) {
                return null;
            }

            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async getFolders(folderId?: IFolderId, brandSlug?: string): Promise<ICampaignFolder[]> {
        const headers: HttpHeaders = await this.setupHeaders();
        const requestUrl = `${this.getApiPrefix(brandSlug)}/Folder`;
        const requestUrlWithParam: string = folderId?.self
            ? `${requestUrl}/${folderId?.self}`
            : `${requestUrl}`;
        const queryParameters: IFolderId = folderId;

        try {
            const response: any = await this.apiService.get(`${requestUrlWithParam}`, {
                anonymous: true,
                headers,
                queryParameters
            });

            if (!folderId && Array.isArray(response)) {
                return response;
            } else if (response && response.subfoldersList) {
                return response.subfoldersList;
            } else {
                return [];
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async getFilteredCampaigns(filter: string): Promise<ICampaignListItem[]> {
        const headers: HttpHeaders = await this.setupHeaders();
        const requestUrlWithParam = `${this.getApiPrefix()}/Folder/Campaigns/Filter?filter=${encodeURIComponent(filter)}`;
        try {
            const response: any = await this.apiService.get(`${requestUrlWithParam}`, {
                anonymous: true,
                headers
            });

            if (Array.isArray(response.campaigns)) {
                return response.campaigns;
            } else {
                return [];
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async getFoldersByCampaignId(campaignId: string): Promise<ICampaignFolder> {
        const headers: HttpHeaders = await this.setupHeaders();
        try {
            const response: any = await this.apiService.get(
                `${this.getApiPrefix()}/Folder/GetByCampaignId`,
                {
                    anonymous: true,
                    headers,
                    queryParameters: { campaignId }
                }
            );

            if (!response) {
                return null;
            }

            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async getAllCampaigns(brandSlug?: string): Promise<ISharedCampaignsFoldersAll> {
        const headers: HttpHeaders = await this.setupHeaders();
        try {
            const response: ISharedCampaignsFoldersAll = await this.apiService.get(
                `${this.getApiPrefix(brandSlug)}/Folder/Campaigns/All`,
                {
                    anonymous: true,
                    headers
                }
            );

            if (!response) {
                return null;
            }

            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async deleteFolder(folderId: IFolderId): Promise<boolean> {
        try {
            const headers: HttpHeaders = await this.setupHeaders();
            return await this.apiService.delete<boolean>(`${this.getApiPrefix()}/Folder`, {
                anonymous: true,
                headers,
                queryParameters: { ...folderId }
            });
        } catch (error) {
            return this.handleError(error);
        }
    }
    public async getFallbackCreativeIds(brandSlug: string): Promise<CreativeFailed[]> {
        try {
            const apiPrefix = `${AppConfig.config.CAMPAIGN_SERVICE_URL}/api/${this.sessionService.user.account.slug}/${brandSlug}`;
            const response: CreativeFailed[] = await this.apiService.get(
                `${apiPrefix}/creative/fallbackCreativesWithFailedInfo`,
                { anonymous: true }
            );

            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    public getReportingLabels(brandId: string): Observable<ReportingLabel[]> {
        const apiPrefix = this.getApiPrefix();
        return this.http.get<ReportingLabel[]>(
            `${apiPrefix}/ReportingLabel?brandId=${brandId}`,
            {}
        );
    }

    public saveReportingLabel(brandId: string, value: string): Observable<any> {
        const apiPrefix = this.getApiPrefix();

        return this.http.post(`${apiPrefix}/ReportingLabel`, { brandId, value });
    }

    public updateReportingLabel(id: string, value: string): Observable<any> {
        const apiPrefix = this.getApiPrefix();

        return this.http.put(`${apiPrefix}/ReportingLabel`, { id, value });
    }

    public deleteReportingLabel(id: string): Observable<any> {
        const apiPrefix = this.getApiPrefix();

        return this.http.delete(`${apiPrefix}/ReportingLabel?Id=${id}`, {});
    }

    private async handleError(error: any): Promise<never> {
        if (error instanceof BFHttpError) {
            if (error.status === 404) {
                // using router because navigatorService creates circular dep.
                this.router.navigate([
                    '/v2',
                    this.sessionService.user.account.slug,
                    this.sessionService.user.brand.slug,
                    '404'
                ]);
            } else if (error.status === 401) {
                // do nothing auth service will automatically redirect
            } else {
                this.errorDialogService.show(
                    {
                        headerText: 'An error has occurred',
                        confirmText: 'Close'
                    },
                    error
                );
            }
        }
        const errorMessage = error instanceof Error ? error : new Error(`Unexpected error: ${JSON.stringify(error)}`);
        return Promise.reject(errorMessage);

    }
}
