import { Serializable } from '@shared/interfaces/serializable.interface';

export class Owner implements Serializable<Owner> {
    public id: string;
    public name: string;
    public email: string;
    public profile: string;

    public deserialize(json: any): this {
        if (!json) { return this; }

        this.id = json.id;
        this.name = json.name;
        this.email = json.email;
        this.profile = json.profile;

        return this;
    }
}
