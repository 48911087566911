<div class="objectPicker">
    <div class="objectPicker__search">
        <input
            type="text"
            placeholder="Search schedule"
            spellcheck="false"
            debounceInput
            (debounceInputChange)="search()"
            [(ngModel)]="searchQuery"
        />
    </div>
    @if (breadcrumbs && breadcrumbs.length) {
<div class="objectPicker__goUp" (click)="goUp()" (keydown)="($event.key === 'Enter' || $event.key === ' ') && goUp()">
        <i
            aria-hidden="true"
            class="folderIcon folderIcon--symbol bf-icon bf-icon-arrow-left-a"
        ></i>
        Go back
    </div>
}
    <div class="objectPicker__table">
        <ngx-datatable
            #datatable
            [class.loading-hidden]="loading"
            [rows]="tableRows"
            [rowHeight]="40"
            [columnMode]="'force'"
            [selected]="selectedObjects"
            [selectionType]="multi ? SelectionType.multiClick : SelectionType.single"
            [selectCheck]="canSelect"
            (activate)="onTableClick($event)"
            (selectedChange)="selectedObjects = $event"
        >
            @if (multi) {
<ngx-datatable-column
               
                [width]="30"
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
                [headerCheckboxable]="true"
                [checkboxable]="true"
            >
            </ngx-datatable-column>
}
            <ngx-datatable-column name="Name" prop="data.name">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        @if (row.isFolder) {
<i
                            aria-hidden="true"
                            class="itemIcon itemIcon--folder bf-icon bf-icon-folder-o"
                           
                        >
                            <div class="hidecheckbox"></div>
                        </i>
}
                        @if (!row.isFolder) {
<i
                            aria-hidden="true"
                            class="itemIcon bf-icon bf-icon-calendar-small"
                           
                        ></i>
}
                        {{ value }}
                        @if (row.isFolder) {
<i
                            aria-hidden="true"
                            class="itemIcon itemIcon--arrow bf-icon bf-icon-arrow-right-b"
                           
                        ></i>
}
                    </span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
        @if (loading) {
<ui-loader></ui-loader>
}
    </div>
</div>
