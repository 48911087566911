import { Serializable } from '@shared/interfaces/serializable.interface';

export class Culture implements Serializable<Culture> {
    public id: string;
    public cultureCode: string;
    public cultureCountry: string;
    public cultureLanguage: string;
    public cultureName: string;

    public deserialize(json: any): this {
        if (!json) {
            return this;
        }

        this.id = json.id;
        this.cultureCode = json.cultureCode;
        this.cultureCountry = json.cultureCountry;
        this.cultureLanguage = json.cultureLanguage;
        this.cultureName = json.cultureName;

        return this;
    }
}
