<div class="sidebar" [ngClass]="{ expand: isExpanded, collapse: !isExpanded }">
    <div class="arrow-button" [attr.data-testid]="'sidebar-expand-toggle'" (click)="toggle()" (keydown)="toggle()">
        <ui-svg-icon [icon]="isExpanded ? 'arrow-left' : 'arrow-right'"></ui-svg-icon>
    </div>
    <div class="container">
        <div
            class="sidebar__item left-align"
            id="interaction-navigate-to-creative-sets"
            (click)="sessionService.exitAnalytics('creative-sets')"
            (keydown)="sessionService.exitAnalytics('creative-sets')"
            uiTooltip="Creative sets"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right"
        >
            <ui-svg-icon icon="creative-set"></ui-svg-icon>
            @if (isExpanded) {
<span class="menu-text"> Creative sets </span>
}
        </div>

        @if (!showIfFeatureEnabled('HIDEBANNERBUILDER')) {

            <div
                class="sidebar__item"
                id="interaction-navigate-to-creative-sets"
                (click)="sessionService.gotoA1Url('BannerSets')"
                (keydown)="sessionService.gotoA1Url('BannerSets')"
                uiTooltip="Banner sets (Legacy)"
                [uiTooltipDisabled]="isExpanded"
                uiTooltipPosition="right"
            >
                <ui-svg-icon [icon]="'banner-sets'"></ui-svg-icon>
                @if (isExpanded) {
<span class="menu-text"> Banner sets (Legacy) </span>
}
            </div>
        
}

        @if (
                showIfUpsellEnabled('SCHEDULING') && !showIfFeatureEnabled('DISABLEBANNERBUILDER')
            ) {
<div
           
            class="sidebar__item"
            id="interaction-navigate-to-schedules"
            (click)="sessionService.gotoA1Url('Schedules')"
            (keydown)="sessionService.gotoA1Url('Schedules')"
            uiTooltip="Schedules (Legacy)"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right"
        >
            <i aria-hidden="true" class="bf-icon bf-icon-schedule"></i>
            @if (isExpanded) {
<span class="menu-text"> Schedules (Legacy) </span>
}
        </div>
}

        @if (campaignManagerFeatureCheck.canUseCampaignList()) {
<div
           
            class="sidebar__item"
            id="interaction-navigate-to-campaign-list"
            (click)="sessionService.exitAnalytics('campaigns')"
            (keydown)="sessionService.exitAnalytics('campaigns')"
            uiTooltip="Campaign Manager"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right"
        >
            <ui-svg-icon icon="campaigns-l"></ui-svg-icon>
            @if (isExpanded) {
<span class="menu-text"> Campaign manager </span>
}
        </div>
}

        @if (showIfFeatureEnabled('ANALYTICS')) {
<div
           
            class="sidebar__item"
            id="interaction-navigate-to-analytics"
            [routerLink]="['./analytics']"
            [routerLinkActive]="['sidebar__item--active']"
            uiTooltip="Analytics"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right"
        >
            <i aria-hidden="true" class="bf-icon bf-icon-analytics"></i>
            @if (isExpanded) {
<span class="menu-text"> Analytics </span>
}
        </div>
}

        @if (showIfUpsellEnabled('FEEDS')) {
<div
           
            class="sidebar__item"
            id="interaction-navigate-to-feeds"
            (click)="sessionService.gotoA1Url('Feeds')"
            (keydown)="sessionService.gotoA1Url('Feeds')"
            uiTooltip="Feeds"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right"
        >
            <i aria-hidden="true" class="bf-icon bf-icon-feeds"></i>
            @if (isExpanded) {
<span class="menu-text">Feeds</span>
}
        </div>
}

        @if (showIfFeatureEnabled('SOCIALDYNAMICADVERTISING')) {
<div
           
            class="sidebar__item"
            id="interaction-navigate-to-social-dynamic-advertising"
            (click)="sessionService.exitAnalytics('feeds')"
            (keydown)="sessionService.exitAnalytics('feeds')"
            uiTooltip="Dynamic Advertising"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right"
        >
            <ui-svg-icon icon="dynamic-feed"></ui-svg-icon>
            @if (isExpanded) {
<span class="menu-text">Dynamic Advertising</span>
}
        </div>
}
        @if (showIfUpsellEnabled('LANDINGPAGE')) {
<div
           
            class="sidebar__item"
            id="interaction-navigate-to-landing-pages"
            (click)="sessionService.gotoA1Url('LandingPages')"
            (keydown)="sessionService.gotoA1Url('LandingPages')"
            uiTooltip="Landing pages"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right"
        >
            <i aria-hidden="true" class="bf-icon bf-icon-landingpage"></i>
            @if (isExpanded) {
<span class="menu-text"> Landing pages </span>
}
        </div>
}

        <ng-container>
            <hr />
            <div
                class="sidebar__item left-align"
                id="interaction-navigate-to-trash"
                (click)="sessionService.exitAnalytics('trash-bin')"
                (keydown)="sessionService.exitAnalytics('trash-bin')"
                uiTooltip="Trash bin"
                [uiTooltipDisabled]="isExpanded"
                uiTooltipPosition="right"
            >
                <ui-svg-icon icon="delete" class="delete-icon"></ui-svg-icon>
                @if (isExpanded) {
<span class="menu-text"> Trash bin </span>
}
            </div>
        </ng-container>
    </div>
</div>
