import { CampaignType } from '@shared/models/shared-campaign-list/shared-campaign-list';
import { CampaignStatus } from '../api/campaign-status.enum';
import { IAdGroup, ICampaign } from '../api/campaign.interface';
import { Model } from '../lib/model';
import { Ad } from './ad.model';
import { PublishAttempt } from './publish-attempt.model';

export class Campaign extends Model<Campaign> implements ICampaign {
    public id: string;
    public name: string;
    public targetUrl?: string;
    public adGroups?: IAdGroup[];
    public ads?: Ad[];
    public attempts: PublishAttempt[];
    public created: string;
    public modified: string;
    public status: CampaignStatus;
    public campaignType: CampaignType;

    public deserialize(json: any): this {
        if (!json) {
            return this;
        }

        this.id = json.id;
        this.name = json.name;
        this.targetUrl = json.targetUrl || undefined;
        this.ads = json.ads ? json.ads.map((ad: any) => new Ad().deserialize(ad)) : [];
        this.attempts = json.attempts
            ? json.attempts.map((attempt: any) => new PublishAttempt().deserialize(attempt))
            : [];
        this.modified = json.modified;
        this.created = json.created;
        this.status = json.status;
        this.adGroups = json.adGroups;

        return this;
    }

    public deserializeForList(json: any): this {
        if (!json) {
            return this;
        }

        this.id = json.id;
        this.name = json.name;
        this.attempts = json.attempts
            ? json.attempts.map((attempt: any) => new PublishAttempt().deserializeForList(attempt))
            : [];
        this.modified = json.modified;
        this.created = json.created;
        this.status = json.status;
        this.adGroups = json.adGroups;

        return this;
    }
}
