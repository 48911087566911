import { Serializable } from '@shared/interfaces/serializable.interface';

export class Palette implements Serializable<Palette> {
    public name = 'New palette';
    public swatch: string[] = [];

    public deserialize(json: any): this {
        if (!json) {
            return null;
        }

        this.name = json.name;
        this.swatch = json.swatch;

        return this;
    }
}
