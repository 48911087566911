@if (brands?.length) {
<div class="headerContentBrandPicker">
    @if (brands.length === 1) {
<div class="headerContentBrandPicker__oneBrand">
        {{ currentBrand.name }}
        <i
            aria-hidden="true"
            id="interaction-navigate-to-brandsettings"
            class="bf-icon bf-icon-settings"
            (click)="gotoSettings(brands[0], $event)"
            (keydown)="gotoSettings(brands[0], $event)"
        ></i>
    </div>
}
    @if (brands.length !== 1) {
<bfDropDown #brandDropDown id="interaction-brandpicker-dropdown">
        <div bfDropDownTitle>
            <div class="headerContentBrandPicker__title">
                {{ currentBrand.name }}
            </div>
        </div>
        <div bfDropDownContent>
            @if (loading) {
<ui-loader></ui-loader>
}
            <!-- If more than 4 brands show brand list -->
            @if (brands.length !== 1 && brands.length > 4) {
<div
                class="headerContentBrandPicker__list"
               
            >
                <div class="headerContentBrandPicker__listItems">
                    @for (brand of brands; track brand) {
<div
                        id="interaction-click-nav-brand-card-compact"
                        class="headerContentBrandPicker__listItem"
                        (click)="changeBrand(brand)"
                        (keydown)="changeBrand(brand)"
                        [ngClass]="{
                            'headerContentBrandPicker__listItem--active':
                                brand.id === currentBrand.id,
                            'headerContentBrandPicker__listItem--noSettings': !hasSettingsFeature
                        }"
                       
                    >
                        <div
                            class="headerContentBrandPicker__listItemLogo"
                            [ngStyle]="{ backgroundImage: 'url(' + brand.logo + ')' }"
                        ></div>
                        <div class="headerContentBrandPicker__listItemCheck">
                            <i aria-hidden="true" class="bf-icon bf-icon-checkmark"></i>
                        </div>
                        @if (hasSettingsFeature) {
<div
                            class="headerContentBrandPicker__listItemSettings"
                           
                            (click)="gotoSettings(brand, $event)"
                            (keydown)="gotoSettings(brand, $event)"
                        >
                            <i
                                aria-hidden="true"
                                id="interaction-brandpicker-navigate-to-brandsettings-compact"
                                class="bf-icon bf-icon-settings-outlined"
                            ></i>
                        </div>
}
                        {{ brand.name }}
                    </div>
}
                </div>
            </div>
}
            @if (brands.length !== 1 && brands.length <= 4) {
<div
                class="headerContentBrandPicker__grid"
               
            >
                @for (brand of brands; track brand) {
<div
                    id="interaction-click-nav-brand-card"
                    class="headerContentBrandPicker__gridItem"
                    [ngClass]="{
                        'headerContentBrandPicker__gridItem--active': brand.id === currentBrand.id
                    }"
                    (click)="changeBrand(brand)"
                    (keydown)="changeBrand(brand)"
                   
                >
                    <div class="headerContentBrandPicker__gridItemInner">
                        <div
                            class="headerContentBrandPicker__gridItemLogo"
                            [ngStyle]="{ backgroundImage: 'url(' + brand.logo + ')' }"
                        ></div>
                        <div class="headerContentBrandPicker__gridItemTitle">{{ brand.name }}</div>
                        <div class="headerContentBrandPicker__gridItemBottom">
                            @if (hasSettingsFeature) {
<div
                                class="headerContentBrandPicker__gridItemBottomIcon headerContentBrandPicker__gridItemBottomIcon--left"
                                (click)="gotoSettings(brand, $event)"
                                (keydown)="gotoSettings(brand, $event)"
                               
                            >
                                <i
                                    aria-hidden="true"
                                    id="interaction-brandpicker-navigate-to-brandsettings"
                                    class="bf-icon bf-icon-settings-outlined"
                                ></i>
                            </div>
}
                            <div
                                class="headerContentBrandPicker__gridItemBottomIcon headerContentBrandPicker__gridItemBottomIcon--right"
                            >
                                <i aria-hidden="true" class="bf-icon bf-icon-checkmark"></i>
                            </div>
                        </div>
                    </div>
                </div>
}
            </div>
}
        </div>
    </bfDropDown>
}
</div>
}
