import { Injectable } from '@angular/core';
import { Size } from '@shared/models/banner/size.model';

@Injectable({ providedIn: 'root' })
export class UtilsService {
    public static getComputedSize(element: any): Size {
        const size: Size = new Size();

        try {
            size.width = parseInt(
                window.getComputedStyle(element, null).getPropertyValue('width'),
                10
            );
            size.height = parseInt(
                window.getComputedStyle(element, null).getPropertyValue('height'),
                10
            );
        } catch (e) {
            size.width = parseInt(element.currentStyle.width, 10);
            size.height = parseInt(element.currentStyle.height, 10);
        }

        return size;
    }

    public static isElementInView(
        element: any,
        threshold: number = 0,
        mode: string = 'visible'
    ): boolean {
        const rect = element.getBoundingClientRect();
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

        const above = rect.bottom - threshold < 0;
        const below = rect.top - viewHeight + threshold >= 0;

        if (mode === 'above') {
            return above;
        } else if (mode === 'below') {
            return below;
        } else {
            return !above && !below;
        }
    }

    public static copyToClipboard(text: string, isMultiLineText?: boolean): void {
        // Save scroll position (safari fix)
        const scrollYPos: number = window.scrollY;

        try {
            const tagName: string = isMultiLineText ? 'textarea' : 'input';
            const input: any = document.createElement(tagName);
            input.style.position = 'fixed';
            document.body.appendChild(input);
            input.value = text;
            input.focus();
            const rangeEnd: number = isMultiLineText ? text.length : 999;
            input.setSelectionRange(0, rangeEnd);
            document.execCommand('copy');
            document.body.removeChild(input);
        } catch (_exception) {
            console.error(_exception);
        }
          finally {
            // Reset scroll position (safari fix)
            window.scrollTo(0, scrollYPos);
        }
    }

    public static isMobile(): boolean {
        return window.innerWidth < 756;
    }
}
