import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { SessionService } from '@core/services/internal/session.service';

type FeatureData = {
    data: {
        requiredFeatures: string[];
        allMustMatch: boolean;
    };
};

@Injectable({
    providedIn: 'root'
})
export class FeatureGuard implements CanActivate {
    private readonly sessionService = inject(SessionService);
    public async canActivate(route: ActivatedRouteSnapshot & FeatureData): Promise<boolean> {
        // user needs to be loaded 
        const user = await this.sessionService.getUserByActivatedRouteSnapshot(route);
        const accountSlug: string = user.brand.accountSlug;
        const brandSlug: string = user.brand.name;
        const routeDestination = `/${accountSlug}/${brandSlug}`;
        const hasFeatures: boolean = this.sessionService.hasFeatures(
            route.data.requiredFeatures,
            route.data.allMustMatch
        );
        if (hasFeatures) {
            return true;
        }
        window.location.assign(routeDestination);
        return false;
    }
}
