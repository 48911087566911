import { Component, Input } from '@angular/core';
import { ObjectDropDownComponent } from '../objectDropDown.component';
import { NgClass } from '@angular/common';
import { BFDropDownComponent } from '../../dropDown/bfDropDown.component';
import { CampaignPickerComponent } from './campaign.picker.component';

@Component({
    selector: 'campaignDropDown',
    templateUrl: 'campaign.dropdown.component.html',
    styleUrls: ['../objectDropDown.component.scss'],
    imports: [NgClass, BFDropDownComponent, CampaignPickerComponent]
})
export class CampaignDropDownComponent extends ObjectDropDownComponent {
    @Input() public placeholder = 'No campaign selected';
}
