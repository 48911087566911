import { Component, ElementRef, Renderer2, inject } from '@angular/core';
import { BrandService } from '@core/services/api/bannerflow/brand.service';
import { ExObjectItem } from '@shared/classes/ExObjectQuery';
import { ObjectType } from '@shared/enums/objectType.enum';
import { Brand } from '@shared/models/brand.model';
import { ObjectPickerComponent } from '../objectPicker.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgStyle } from '@angular/common';
import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'brandPicker',
    templateUrl: 'brand.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    imports: [NgxDatatableModule, NgStyle, UIModule]
})
export class BrandPickerComponent extends ObjectPickerComponent {
    private readonly brandService = inject(BrandService);
    public readonly elementRef = inject(ElementRef);
    public readonly renderer = inject(Renderer2);
    public type: ObjectType = ObjectType.Brand;
    public tableRows: (ExObjectItem<Brand> | Brand)[] = [];

    protected async loadItems(folder: string = ''): Promise<void> {
        this.loading = true;
        this.tableRows = await this.brandService.getBrands();
        super.loadItems();
        this.datatable.recalculate();

        this.loading = false;
    }
}
