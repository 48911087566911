import { Component, Input } from '@angular/core';
import { ObjectDropDownComponent } from '../objectDropDown.component';
import { NgClass } from '@angular/common';
import { BFDropDownComponent } from '../../dropDown/bfDropDown.component';
import { BannerFormatPickerComponent } from './bannerformat.picker.component';

@Component({
    selector: 'bannerFormatDropDown',
    templateUrl: 'bannerformat.dropdown.component.html',
    styleUrls: ['../objectDropDown.component.scss'],
    imports: [NgClass, BFDropDownComponent, BannerFormatPickerComponent]
})
export class BannerFormatDropDownComponent extends ObjectDropDownComponent {
    @Input() public placeholder = 'No banner format selected';
    @Input() public bannerSetId: string;
}
