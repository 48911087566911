<div class="objectDropDown" [ngClass]="{ 'objectDropDown--active': selected && selected.length }">
    <bfDropDown (open)="onOpen()" (close)="onClose()">
        <div bfDropDownTitle>
            {{ label }}
            @if (multi && selected.length) {
<i
                aria-hidden="true"
                class="objectDropDown__clear bf-icon bf-icon-close-filled"
                (click)="clear($event)"
                (keydown)="($event.key === 'Enter' || $event.key === ' ') && clear($event)"
               
            ></i>
}
        </div>
        <div bfDropDownContent>
            @if (isOpen) {
<sizeFormatPicker
                #objectPicker
                [multi]="multi"
                [(selected)]="selected"
                (selectedChange)="change($event)"
               
            ></sizeFormatPicker>
}
        </div>
    </bfDropDown>
</div>
