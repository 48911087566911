import { CreativeSet } from '@shared/models/studio/creativeset.model';
import { Localization } from '@shared/models/version/localization.model';
import { AdStatus } from '../api/campaign-status.enum';
import { IAd, IAdGroup, IAnalyticsAdCampaign } from '../api/campaign.interface';
import { Model } from '../lib/model';
import { AdSize } from './ad-size.model';
import { CreativeRef } from './creative-ref.model';

export class Ad extends Model<Ad> implements IAd {
    public id: string;
    public displayName: string;
    public resolvedName?: string;
    public creativeRefs: CreativeRef[];
    public size: AdSize;
    public localizationId: string;
    public targetUrl: string;
    public status: AdStatus;
    public account?: string;
    public adGroupId?: string;
    public campaignId?: string;
    public campaignName?: string;
    public brand?: string;

    // these are not part of campaign api
    public language?: Localization;
    public creativeSets?: CreativeSet[];

    public deserialize(json: any): this {
        if (!json) {
            return this;
        }

        this.id = json.id;
        this.displayName = json.displayName;
        this.creativeRefs = json.creativeRefs
            ? json.creativeRefs.map((ref: any) => new CreativeRef().deserialize(ref))
            : [];
        this.size = new AdSize().deserialize(json.size);
        this.localizationId = json.localizationId;
        this.targetUrl = json.targetUrl;
        this.status = json.status;
        this.account = json.account ? json.account : '';
        this.adGroupId = json.adGroupId ? json.adGroupId : '';
        this.campaignId = json.campaignId ? json.campaignId : '';
        this.campaignName = json.campaignName ? json.campaignName : '';
        this.brand = json.brand ? json.brand : '';

        return this;
    }

    public hasCreativeSets(): boolean {
        return Array.isArray(this.creativeSets) && this.creativeSets.length > 0;
    }
}

export class AnalyticsAd extends Model<AnalyticsAd> implements IAnalyticsAdCampaign {
    public id: string;
    public displayName: string;
    public campaignId: string;
    public campaignName: string;
    public size: AdSize;
    public localizationId: string;
    public language?: string;

    public deserialize(json: any): this {
        if (!json) {
            return this;
        }

        this.id = json.id;
        this.displayName = json.displayName;
        this.campaignId = json.campaignId;
        this.campaignName = json.campaignName;
        this.size = new AdSize().deserialize(json.size);
        this.localizationId = json.localizationId;

        return this;
    }
}

export class AdGroup extends Model<AdGroup> implements IAdGroup {
    public id: string;
    public name: string;

    public deserialize(json: any): this {
        if (!json) {
            return this;
        }

        this.id = json.id;
        this.name = json.name;

        return this;
    }
}
