import { Injectable, inject } from '@angular/core';
import { SizeFormat } from '@shared/models/banner/sizeFormat.model';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class SizeFormatService {
    private readonly apiService = inject(ApiService);
    private sizeFormatPromise: Promise<SizeFormat[]>;

    public getSizeFormats(): Promise<SizeFormat[]> {
        if (this.sizeFormatPromise !== undefined) {
            return this.sizeFormatPromise;
        }
        this.sizeFormatPromise = new Promise<SizeFormat[]>((resolve) => {
            this.apiService
                .get('/api/v1/b/[accountSlug]/[brandSlug]/sizeformats', { anonymous: true })
                .then((data: any) => {
                    const sizeFormats = data.map((sizeFormat: any) => new SizeFormat().deserialize(sizeFormat));
                    resolve(sizeFormats);
                });
        });
        return this.sizeFormatPromise;
    }
}
