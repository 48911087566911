import { ISize } from '../../size';
import { Model } from '../lib/model';

export class AdSize extends Model<ISize> implements ISize {
    public id: string;
    public width: number;
    public height: number;
    public name?: string;

    public deserialize(json: any): this {
        if (!json) {
            return this;
        }

        this.id = json.id;
        this.width = json.width;
        this.height = json.height;
        this.name = json.name ? json.name : '';

        return this;
    }
}
