import { CampaignStatus } from '../campaign/api/campaign-status.enum';
import { IFolderId } from '../campaign/api/campaign.interface';

export enum CampaignType {
    Display = 0,
    Social = 1
}

export interface ISharedFolderId extends IFolderId {
}

export interface ISharedCampaignFolder {
    name: string;
    folderId: ISharedFolderId;
    campaigns: string[];
    subfoldersList: ISharedCampaignFolder[];
    modified: string;
    created: string;
}

export interface ISharedCampaignListItem {
    id: string;
    name: string;
    created?: string;
    modified?: string;
    status?: CampaignStatus;
    pending?: boolean;
    published?: boolean;
    campaignType: CampaignType;
    isMigrating?: boolean;
}

export interface IPreviousCampaignListItemLocation {
    node: any;
    destination: IFolderId;
    source: IFolderId;
    unDone?: boolean;
}
