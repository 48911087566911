import { Component, ViewContainerRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService, GenericError } from '@auth0/auth0-angular';
import { AppService } from '@core/services/internal/app.service';
import { filter, mergeMap } from 'rxjs';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    imports: [RouterOutlet]
})
export class AppComponent {
    appService = inject(AppService);
    viewContainerRef = inject(ViewContainerRef);
    private readonly authService = inject(AuthService);

    private readonly relevantErrors = new Set([
        'login_required',
        'invalid_grant',
        'access_denied',
        'unauthorized_client'
    ]);
        constructor() {
        this.authService.error$
            .pipe(
                takeUntilDestroyed(),
                filter((e) => e instanceof GenericError && this.relevantErrors.has(e.error)),
                mergeMap(() => this.authService.logout())
            )
            .subscribe();
    }
}