import { Component, Input } from '@angular/core';
import { ObjectDropDownComponent } from '../objectDropDown.component';
import { NgClass } from '@angular/common';
import { BFDropDownComponent } from '../../dropDown/bfDropDown.component';
import { LocalizationPickerComponent } from './localization.picker.component';

@Component({
    selector: 'localizationDropDown',
    templateUrl: 'localization.dropdown.component.html',
    styleUrls: ['../objectDropDown.component.scss'],
    imports: [NgClass, BFDropDownComponent, LocalizationPickerComponent]
})
export class LocalizationDropDownComponent extends ObjectDropDownComponent {
    @Input() public placeholder = 'No localization selected';
}
