import { Component, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { AppService } from '@core/services/internal/app.service';
import { CookieService } from '@core/services/internal/cookie.service';
import { InitializationService } from '@core/services/internal/initialization.service';
import { NavigatorService } from '@core/services/internal/navigator.service';
import { SidebarService } from '@core/services/internal/sidebar.service';
import {
    NotificationInfo,
    StickyNotificationService
} from '@core/services/internal/sticky-notification.service';
import { Observable } from 'rxjs';
import { UIModule } from '@bannerflow/ui';
import { HeaderContentComponent } from './shared/components/headerContent/headerContent.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { WelcomePanelComponent } from './shared/components/welcomePanel/welcomePanel.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';

@Component({
    selector: 'mainView',
    templateUrl: 'app.view.component.html',
    styleUrls: ['app.view.component.scss'],
    imports: [
    UIModule,
    HeaderContentComponent,
    NgClass,
    WelcomePanelComponent,
    SidebarComponent,
    RouterOutlet,
    AsyncPipe
]
})
export class AppViewComponent implements OnInit {
    appService = inject(AppService);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly navigatorService = inject(NavigatorService);
    private readonly sidebarService = inject(SidebarService);
    private readonly stickyNotificationService = inject(StickyNotificationService);
    private readonly initializationService = inject(InitializationService);

    isExpanded: boolean;
    stickyNotification$: Observable<NotificationInfo>;

    constructor() {
        this.navigatorService.rootRoute = this.activatedRoute;
        this.stickyNotification$ = this.stickyNotificationService.notificationInfo$;
        this.appService.setIsInitialized();
        this.sidebarService
            .isExpanded()
            .pipe(takeUntilDestroyed())
            .subscribe((isExpanded: boolean) => (this.isExpanded = isExpanded));
    }

    async ngOnInit(): Promise<void> {
        await this.initializationService.initialize();
    }

    public onLogoClick(): void {
        CookieService.set('newLogin', 'true');
        this.navigatorService.goHome();
    }
}
