import { Component, HostListener, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BrandService } from '@core/services/api/bannerflow/brand.service';
import { NavigatorService } from '@core/services/internal/navigator.service';
import { SessionService } from '@core/services/internal/session.service';
import { Brand } from '@shared/models/brand.model';
import { NgClass, NgStyle } from '@angular/common';
import { BFDropDownComponent } from '../../dropDown/bfDropDown.component';
import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'headerContentBrandPicker',
    styleUrls: ['./brandPicker.component.scss'],
    templateUrl: 'brandPicker.component.html',
    imports: [BFDropDownComponent, UIModule, NgClass, NgStyle]
})
export class HeaderContentBrandPickerComponent implements OnInit {
    private readonly sessionService = inject(SessionService);
    private readonly brandService = inject(BrandService);
    private readonly navigatorService = inject(NavigatorService);
    private readonly router = inject(Router);
    public currentBrand: Brand;
    public brands: Brand[] = [];
    public loading: boolean;
    private unloadTimer: any;
    public hasSettingsFeature: boolean;


    public async ngOnInit(): Promise<void> {
        this.loading = true;
        this.currentBrand = this.sessionService.user.brand;
        this.brands = await this.brandService.getBrands(false, true);
        this.loading = false;
        this.hasSettingsFeature = this.sessionService.hasFeature(SessionService.FEATURES.SETTINGS);
    }

    // If the user cancels moving away from the page, we need to set loading to false.
    @HostListener('window:beforeunload', ['$event'])
    public handleBeforeUnload(): void {
        this.unloadTimer = setTimeout(() => {
            this.loading = false;
        }, 500);
    }
    @HostListener('window:onunload')
    public handleUnload(): void {
        clearInterval(this.unloadTimer);
    }

    public changeBrand(brand: Brand): void {
        this.loading = true;
        setTimeout(() => {
            this.navigatorService.switchBrand(brand);
        }, 100);
    }

    public gotoSettings(brand: Brand, event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.loading = true;
        setTimeout(() => {
            this.goToA2SettingView(brand);
        }, 100);
    }

    public goToA2SettingView(brand: Brand): void {
        this.router.navigate([
            `/${brand.accountSlug}/${brand.slug}/settings/brand/${brand.slug}/overview`
        ]);
    }
}
