import { Injectable, inject } from '@angular/core';
import { SessionService } from './session.service';
import { ProfileService } from '../api/profile/profile.service';
import { InfoService } from '../api/bannerflow/info.service';
import { AppConfig } from '@config/app.config';
import { initGTM } from '@plugins/scripts/js/gtm';
import { User } from '@shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class InitializationService {
  private readonly sessionService = inject(SessionService);
  private readonly profileService = inject(ProfileService);
  private readonly infoService = inject(InfoService);

  async initialize(): Promise<void> {
    const user: User | undefined = this.sessionService.user;
    if (!user) {
      // No user in session—nothing to initialize.
      console.warn('⚠️ No user found in session. Skipping initialization...');
      return;
    }
    try {
      const userTitle = await this.profileService.getUserTitle(user.externalId);
      this.sessionService.user.title = userTitle?.name;
    } catch (error) {
      console.error('🚨 InitializationService: Failed to fetch user title', error);
    }

    try {
      await this.infoService.initBFInfoGlobalVariable();
    } catch (error) {
      console.error('🚧 InitializationService: Failed to initialize Bannerflow info', error);
    }

    // 3. Initialize Google Tag Manager (if configured)
    if (AppConfig.config.GTM_ID && AppConfig.config.STAGE !== 'local') {
      try {
        initGTM(AppConfig.config.GTM_ID);
      } catch (error) {
        console.error('🐞 InitializationService: Failed to initialize Google Tag Manager', error);
      }
    }
  }
}


