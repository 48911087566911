import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CampaignManagerFeatureCheckService } from '@core/services/internal/campaign-manager-feature-check.service';
import { SessionService } from '@core/services/internal/session.service';
import { SidebarService } from '@core/services/internal/sidebar.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgClass } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'sidebar',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['sidebar.component.scss'],
    imports: [NgClass, UIModule, RouterLink, RouterLinkActive]
})
export class SidebarComponent implements OnInit, OnDestroy {
    sessionService = inject(SessionService);
    campaignManagerFeatureCheck = inject(CampaignManagerFeatureCheckService);
    private readonly sidebarService = inject(SidebarService);
    isExpanded: boolean;
    private readonly ngUnsubscribe$ = new Subject<void>();

    ngOnInit(): void {
        this.sidebarService
            .isExpanded()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((isExpanded) => (this.isExpanded = isExpanded));
    }
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
    }
    public showIfUpsellEnabled(feature: string): boolean {
        const showUpsale: boolean = this.showIfFeatureEnabled('UPSELLVIEWS');
        if (showUpsale) {
            return true;
        }
        return this.showIfFeatureEnabled(feature);
    }

    toggle(): void {
        this.sidebarService.toggle();
    }
    public showIfFeatureEnabled(feature: string): boolean {
        return this.sessionService.hasFeature(SessionService.FEATURES[feature]);
    }
}
