import { Component, ElementRef, Input, Renderer2, inject } from '@angular/core';
import { BannerFormatService } from '@core/services/api/bannerflow/bannerformat.service';
import { ObjectType } from '@shared/enums/objectType.enum';
import { BannerFormat } from '@shared/models/banner/bannerFormat.model';
import { ObjectPickerComponent } from '../objectPicker.component';
import { FormsModule } from '@angular/forms';
import { DebounceInputDirective } from '../../../directives/debounceInput.directive';

import { UIModule } from '@bannerflow/ui';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
    selector: 'bannerFormatPicker',
    templateUrl: 'bannerformat.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    imports: [FormsModule, DebounceInputDirective, UIModule, NgxDatatableModule]
})
export class BannerFormatPickerComponent extends ObjectPickerComponent {
    private readonly bannerFormatService = inject(BannerFormatService);
    public readonly elementRef = inject(ElementRef);
    public readonly renderer = inject(Renderer2);
    public type: ObjectType = ObjectType.BannerFormat;
    public tableRows: BannerFormat[] = [];
    @Input() private readonly bannerSetId: string;

    protected async loadItems(): Promise<any> {
        this.loading = true;

        await this.bannerFormatService
            .getBannerFormats(this.bannerSetId)
            .then((bannerFormats: BannerFormat[]) => {
                this.tableRows = bannerFormats;
            });
        super.loadItems();
        this.datatable.recalculate();
        this.loading = false;
    }
}
