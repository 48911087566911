import { Component, ElementRef, Renderer2, inject } from '@angular/core';
import { LocationService } from '@core/services/internal/location.service';
import { ObjectType } from '@shared/enums/objectType.enum';
import { BannerFormat } from '@shared/models/banner/bannerFormat.model';
import { ObjectPickerComponent } from '../objectPicker.component';
import { FormsModule } from '@angular/forms';
import { DebounceInputDirective } from '../../../directives/debounceInput.directive';

import { UIModule } from '@bannerflow/ui';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
    selector: 'countryPicker',
    templateUrl: 'country.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    imports: [FormsModule, DebounceInputDirective, UIModule, NgxDatatableModule]
})
export class CountryPickerComponent extends ObjectPickerComponent {
    private readonly locationService = inject(LocationService);
    public readonly elementRef = inject(ElementRef);
    public readonly renderer = inject(Renderer2);
    public type: ObjectType = ObjectType.Country;
    public tableRows: (BannerFormat | { id: string; name: string })[] = [];

    protected loadItems(): void {
        this.tableRows = this.locationService.countryList.map((country: any) => ({
            id: country.countryCode,
            name: country.name
        }));
        super.loadItems();
    }
}
