import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CookieService } from '@core/services/internal/cookie.service';
import { Observable, concatMap, from } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
    private readonly authService = inject(AuthService);

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        // Don't intercept cypress tests
        if (CookieService.get('IN_TEST')) {
            return next.handle(req);
        }

        // public preview doesn't require access token
        if (req.url.includes('/api/v1/s/publicpreview') || req.url.includes('/api/v1/s/preview')) {
            return next.handle(req);
        }

        return from(this.authService.getAccessTokenSilently()).pipe(
            concatMap((token) => {
                let headers: HttpHeaders = req.headers;

                headers = headers.set('Authorization', `Bearer ${token}`);

                if (!req.headers.has('Content-Type')) {
                    headers = headers.append('Content-Type', 'application/json');
                }

                const authReq = req.clone({
                    headers
                });

                return next.handle(authReq);
            })
        );
    }
}
