<div
    class="profileImage"
    [ngStyle]="{ backgroundColor: userColor }"
    [ngClass]="{
        'profileImage--hasArrow': showArrow,
        'profileImage-small': small
    }"
>
    {{ initials }}
    @if (showArrow) {
<div
        class="profileImage-arrow"
        [ngStyle]="{ borderTopColor: userColor }"
       
    ></div>
}
</div>
