import { Serializable } from '@shared/interfaces/serializable.interface';

export class AdType implements Serializable<AdType> {
    public id: string;
    public name: string;
    public displayName: string;
    public stopRender: boolean;
    public isRegularAd: boolean;
    public isAdvancedAd: boolean;

    public isContained(): boolean {
        return this.name === 'cube' || this.name === 'slider';
    }

    public deserialize(json: any): this {
        if (!json) { return this; }

        this.id = json.id;
        this.name = json.name;
        this.displayName = json.displayName;
        this.stopRender = json.stopRender;
        this.isRegularAd = json.type === 0;
        this.isAdvancedAd = json.type === 1;
        return this;
    }
}
