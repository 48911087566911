import { Serializable } from '@shared/interfaces/serializable.interface';
import { SizeFormat } from './banner/sizeFormat.model';
import { User } from './user.model';
import { Culture } from './version/culture.model';

export class Comment implements Serializable<Comment> {
    public id: string;
    public adId: string;
    public bannerSetId: string;
    public sizeFormat: SizeFormat;
    public culture: Culture;
    public email: string;
    public user: User;
    public created: Date;
    public comments: Comment[];
    public approved: boolean;
    public commentId: string;
    public text: string;
    public totalComments = 0;

    public new: boolean;

    public deserialize(json: any): this {
        if (!json) {
            return this;
        }

        this.id = json.id;
        this.adId = json.adId;
        this.text = json.text;
        this.bannerSetId = json.bannerSetId;
        this.approved = json.approved;
        this.culture = new Culture().deserialize(json.culture);
        this.sizeFormat = new SizeFormat().deserialize(json.sizeFormat);
        this.created = json.created;
        this.totalComments = json.totalComments;

        this.comments = [];
        json.children.forEach((child: any) => {
            this.comments.push(new Comment().deserialize(child));
        });

        this.user = new User().deserialize(json.user);

        return this;
    }
}
