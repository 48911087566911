import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
    IBFError,
    IBFHttpError,
    INotificationConfig,
    IUIErrorDialogConfig,
    UIErrorDialogService,
    UINotificationService
} from '@bannerflow/ui';
import { AppConfig } from '@config/app.config';
import { CacheService } from '@core/services/internal/cache.service';
import { SessionService } from '@core/services/internal/session.service';
import { User } from '@shared/models/user.model';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

type AllowedMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'FETCH';

export class BFError implements IBFError {
    public title: string;
    public message: string;
    public code: number;

    constructor(params: Partial<BFError> = {}) {
        Object.assign(this, params);
    }
}

export class BFHttpError extends BFError implements IBFHttpError {
    public requestId: string;
    public requestUrl: string;
    public status: number;
    public originalResponse: any;

    public get logglyUrl(): string {
        if (!this.requestId) {
            return '';
        }
        const fromDate: Date = new Date(Date.now() - 1000 * 60 * 5);
        const encodedDate: string = encodeURIComponent(fromDate.toISOString());

        return `https://bannerflow.loggly.com/search#terms=${this.requestId}&from=${encodedDate}`;
    }

    constructor(response?: Response) {
        super();

        this.originalResponse = response;
        if (response) {
            let json: any;

            // If not json prevent crash
            if (response.headers.get('content-type') === 'application/json') {
                json = response.json();
            }
            if (json) {
                this.message = json.message;
            } else if (this.originalResponse.error?.message) {
                this.message = this.originalResponse.error.message;
            }
            this.title = response.statusText;
            this.status = response.status;
            this.requestUrl = response.url;

            if (response.headers) {
                this.requestId = response.headers.get('bannerflow-request-id');
            }
        }
    }
}


export interface IApiOptions {
    cache?: boolean;

    errorNotification?: boolean;

    anonymous?: boolean;

    headers?: HttpHeaders;

    queryParameters?: any;

    isExpectingLocationHeader?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ApiService {
    private readonly http = inject(HttpClient);
    private readonly cacheService = inject(CacheService);
    private readonly sessionService = inject(SessionService);
    private readonly errorDialogService = inject(UIErrorDialogService);
    private readonly notificationService = inject(UINotificationService);
    private readonly authService = inject(AuthService);
    public get<T>(url: string, options?: IApiOptions): Promise<any | T | BFHttpError> {
        return this.makeRequest(url, 'GET', null, options);
    }

    public fetch<T>(url: string, options?: IApiOptions): Promise<any | T | BFHttpError> {
        return this.makeRequest(url, 'FETCH', null, options);
    }

    public post<T>(url: string, data: any, options?: IApiOptions): Promise<any | T | BFHttpError> {
        return this.makeRequest(url, 'POST', data, options);
    }

    public put<T>(url: string, data: any, options?: IApiOptions): Promise<any | T | BFHttpError> {
        return this.makeRequest(url, 'PUT', data, options);
    }

    public patch<T>(url: string, data: any, options?: IApiOptions): Promise<any | T | BFHttpError> {
        return this.makeRequest(url, 'PATCH', data, options);
    }

    public delete<T>(url: string, options?: IApiOptions): Promise<any | T | BFHttpError> {
        return this.makeRequest(url, 'DELETE', null, options);
    }

    public toQueryString(params: any): string {
        let queryParams: HttpParams = new HttpParams();
        for (const i in params) {
            if (Array.isArray(params[i])) {
                params[i].forEach((param: any) => {
                    queryParams = queryParams.append(String(i), String(param));
                });
            } else {
                queryParams = queryParams.append(String(i), String(params[i]));
            }
        }

        return queryParams.toString();
    }

    protected async setupHeaders(): Promise<HttpHeaders> {
        const token: string = await firstValueFrom(this.authService.getAccessTokenSilently());
        const headers: HttpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        });

        return headers;
    }

    private async makeRequest(
        url: string,
        method: AllowedMethods,
        body: any,
        options: IApiOptions = {}
    ): Promise<any | BFHttpError> {
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

        if (options.headers) {
            headers = options.headers;
        }

        const requestOptions = { headers, body };
        const requestUrl: string = this.getRequestUrl(url, options);

        if (method !== 'DELETE' && options.cache) {
            const cacheKey: string = url + JSON.stringify(body);
            const cachedResponse: any = this.cacheService.get(cacheKey);

            if (cachedResponse) {
                return Promise.resolve<any>(cachedResponse);
            }
        }
        if (options.isExpectingLocationHeader) {
            return firstValueFrom(
                this.http.request(method, requestUrl, { body, headers, observe: 'response' })
            )
                .then((httpRes: HttpResponse<any>) => firstValueFrom(
                        this.http.request(
                            'GET',
                            this.getRequestUrl(httpRes.headers.get('location'), options),
                            requestOptions
                        )
                    )
                        .then((res: Response) => this.extractData(res, false, null))
                        .catch((response: Response) => this.handleError(response, options.errorNotification)))
                .catch((response: Response) => this.handleError(response, options.errorNotification));
        }
        return firstValueFrom(this.http.request(method, requestUrl, requestOptions))
            .then((res: Response) => this.extractData(res, false, null))
            .catch((response: Response) => this.handleError(response, options.errorNotification));
    }

    private getRequestUrl(url: string, options: IApiOptions = {}): string {
        let requestUrl = '';
        const user: User = this.sessionService.user;

        if (user) {
            const macros: any = {
                accountId: user.account.id,
                accountSlug: user.account.slug,
                brandSlug: user.brand.slug,
                brandId: user.brand.id,
                userId: user.id
            };

            for (const key in macros) {
                url = url.replace(new RegExp(`\\[${key}\\]`, 'ig'), macros[key]);
            }
        }

        const absolute: boolean = /^(https?:)?(\/\/)+/g.test(url);

        if (absolute) {
            requestUrl = url;
        } else if (options.anonymous) {
            requestUrl = `${AppConfig.config.B2_URL}${url}`;
        } else {
            // Support urls which doesn't start with '/'
            if (!url.startsWith('/')) {
                url = `/${url}`;
            }
            requestUrl = `${AppConfig.config.B2_URL}/api/v2/${this.sessionService.user.account.slug}/${this.sessionService.user.brand.slug}${url}`;
        }

        if (options.queryParameters) {
            requestUrl =
                requestUrl +
                (requestUrl.indexOf('?') === -1 ? '?' : '&') +
                this.toQueryString(options.queryParameters);
        }

        return requestUrl;
    }

    private handleError<T>(
        error?: Response,
        showNotificationOnHandledError?: boolean,
        jsonError?: boolean
    ): Promise<void> {
        const bfHttpError: BFHttpError = new BFHttpError(error);

        if (jsonError) {
            bfHttpError.message = 'Could not parse json string';
        }

        if (bfHttpError.status === 500 || jsonError) {
            const config: IUIErrorDialogConfig = {};
            this.errorDialogService.show(config, bfHttpError);
        } else if (showNotificationOnHandledError) {
            let msg: string = bfHttpError.message ? bfHttpError.message : 'Something went wrong!';

            if (bfHttpError.status === 409) {
                msg = 'Conflict with other item, please try again';
            }

            const config: INotificationConfig = {
                type: 'error',
                autoCloseDelay: 5000,
                placement: 'top'
            };

            this.notificationService.open(msg, config);
        }

        return Promise.reject(new Error(bfHttpError.message));
    }

    private extractData<T>(res: Object, cache: boolean, cacheKey: string): any {
        if (cache) {
            this.cacheService.add(cacheKey, res);
        }
        return res;
    }
}
