import { Pipe, PipeTransform, inject } from '@angular/core';
import { CacheService } from '@core/services/internal/cache.service';

interface ITextStyle {
    fontFamily: string;
    fontSize: string;
}
@Pipe({ name: 'ellipsis',  })
export class EllipsisPipe implements PipeTransform {
    private readonly cacheService = inject(CacheService);
    public static ellipsis(text: any, maxLength: any = 15): string {
        if (!text?.toString) {
            return '';
        }
        text = text.toString();

        if (text.length > maxLength) {
            return `${text.substring(0, maxLength)}...`;
        }
        return text;
    }
    public static ellipsisMiddle(text: any, maxLength: any = 15): string {
        if (!text?.toString) {
            return '';
        }
        text = text.toString();
        if (text.length > maxLength) {
            return `${text.substring(0, maxLength / 2)}...${text.substring(text.length - maxLength / 2, text.length)}`;
        }
        return text;
    }
    public transform(
        text: any,
        maxLength: any = 15,
        cutMiddle?: boolean,
        containerWidth?: number,
        textStyle?: ITextStyle
    ): string {
        if (containerWidth) {
            const charWidth: number = this.getAverageCharWidth(textStyle);
            const padding = 8;
            maxLength = containerWidth / charWidth - padding;
        }
        const method: any = cutMiddle ? EllipsisPipe.ellipsisMiddle : EllipsisPipe.ellipsis;

        return method(text, maxLength);
    }

    private getAverageCharWidth(
        textStyle: ITextStyle = { fontFamily: 'Open Sans', fontSize: '12' }
    ): number {
        const cacheKey: string = textStyle.fontFamily.replace(' ', '') + textStyle.fontSize;
        let cacheItem: number = this.cacheService.get(cacheKey);

        if (!cacheItem) {
            cacheItem = this.calculateAverageCharWidth(textStyle);
            this.cacheService.add(cacheKey, cacheItem);
        }
        return cacheItem;
    }

    private calculateAverageCharWidth(textStyle: ITextStyle): number {
        const el: HTMLElement = document.createElement('div');
        const sampleString =
            ' !"#$%&()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[ ]^_`abcdefghijklmnopqrstuvwxyz{|}~ Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
        el.innerText = sampleString;
        el.style.opacity = '0';
        el.style.position = 'absolute';
        el.style.whiteSpace = 'nowrap';
        el.style.fontSize = `${textStyle.fontSize}px`;
        el.style.fontFamily = textStyle.fontFamily;
        document.body.appendChild(el);
        const averageCharWidth: number = el.offsetWidth / sampleString.length;
        document.body.removeChild(el);
        return averageCharWidth;
    }
}
