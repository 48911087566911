<ui-header (logoClick)="onLogoClick()" [full]="true">
    <headerContent></headerContent>
</ui-header>

@if (stickyNotification$ | async; as stickyNotification) {

    @if (stickyNotification.shouldShow) {
<ui-sticky-notification
       
        data-testid="sticky-notification"
        class="sticky-notification"
        [type]="stickyNotification.type"
    >
        <div [ngClass]="stickyNotification.type">
            @if (stickyNotification.loader) {
<ui-loader
                class="sticky-loader"
                [inline]="true"
               
            ></ui-loader>
}
            {{ stickyNotification.message }}
        </div>
    </ui-sticky-notification>
}

}
<ui-body [full]="true">
    <div class="content-wrapper" [ngClass]="{ noSidebar: !appService.settings?.sidebar }">
        <welcomePanel></welcomePanel>
        <sidebar></sidebar>
        <div class="content">
            <div class="page" [ngClass]="isExpanded && 'expandable-margin'">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</ui-body>
