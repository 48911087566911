import { Component, Input } from '@angular/core';
import { ObjectDropDownComponent } from '../objectDropDown.component';
import { NgClass } from '@angular/common';
import { BFDropDownComponent } from '../../dropDown/bfDropDown.component';
import { SchedulePickerComponent } from './schedule.picker.component';

@Component({
    selector: 'scheduleDropDown',
    templateUrl: 'schedule.dropdown.component.html',
    styleUrls: ['../objectDropDown.component.scss'],
    imports: [NgClass, BFDropDownComponent, SchedulePickerComponent]
})
export class ScheduleDropDownComponent extends ObjectDropDownComponent {
    @Input() public placeholder = 'No schedule selected';
}
