import { Injectable, inject } from '@angular/core';
import { BannerFormat } from '@shared/models/banner/bannerFormat.model';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class BannerFormatService {
    private readonly apiService = inject(ApiService);
    public getBannerFormats(bannerSetId: string): Promise<BannerFormat[]> {
        // Fetch brands and store result in brand promise for later use
        return this.apiService.get(`bannersets/${bannerSetId}/bannerformats`).then((data: any) => {
            const versions = data.map((bannerFormat: any) => new BannerFormat().deserialize(bannerFormat));
            return Promise.resolve(versions);
        });
    }
}
