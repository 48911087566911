import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, Event } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { AppService } from '@core/services/internal/app.service';
import { HistoryService } from '@core/services/internal/history.service';
import { NavigatorService } from '@core/services/internal/navigator.service';
import { SessionService } from '@core/services/internal/session.service';
import { HistoryView } from '@shared/models/routing-parameter.model';
import { Subject, takeUntil } from 'rxjs';
import { BFDropDownComponent } from '../dropDown/bfDropDown.component';

import { UIModule } from '@bannerflow/ui';
import { HeaderContentBrandPickerComponent } from './brandPicker/brandPicker.component';
import { EnvironmentPickerComponent } from './environment-picker/environment-picker.component';
import { ProfileImageComponent } from '../profileImage/profileImage.component';

declare const Intercom: any;

@Component({
    selector: 'headerContent',
    styleUrls: ['./headerContent.component.scss'],
    templateUrl: 'headerContent.component.html',
    imports: [
    UIModule,
    HeaderContentBrandPickerComponent,
    EnvironmentPickerComponent,
    ProfileImageComponent
]
})
export class HeaderContentComponent implements OnInit, OnDestroy {
    sessionService = inject(SessionService);
    appService = inject(AppService);
    private readonly navigatorService = inject(NavigatorService);
    private readonly historyService = inject(HistoryService);
    @ViewChild('brandDropDown') public brandDropDown: BFDropDownComponent;
    private readonly profileRoute: string[];
    private previousView: HistoryView;
    private readonly onDestroy$: Subject<void> = new Subject();
    public hasSettingsFeature: boolean;
    public hasStudioFeature: boolean;
    public hasSocialCampaignManagerFeature: boolean;
    public isSandbox: boolean;
    public welcomePanelVisible: boolean;
    public isPublicShowcase: boolean;
    public Intercom: any = Intercom;
    public showBrandPicker: boolean;
    private readonly router = inject(Router);


    public async ngOnInit(): Promise<void> {
        this.isSandbox = AppConfig.config.STAGE === 'sandbox';
        if (this.sessionService.isLoggedIn()) {
            this.hasSettingsFeature = this.sessionService.hasFeature(
                SessionService.FEATURES.SETTINGS
            );
            this.hasStudioFeature = this.sessionService.hasFeature(SessionService.FEATURES.STUDIO);
            this.hasSocialCampaignManagerFeature = this.sessionService.hasFeature(
                SessionService.FEATURES.SOCIALCAMPAIGNMANAGER
            );

            this.appService.welcomePanelToggled
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((visible: boolean) => {
                    this.welcomePanelVisible = visible;
                });

            this.previousView = this.historyService.previousView;

            this.historyService.previousViewChanged
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((view: HistoryView) => {
                    this.previousView = view;
                });
        }

        const routes: string[] = this.router.url.split('/').slice(1);
        if (routes[0] === 'p') {
            this.isPublicShowcase = true;
        }

        this.updateShowBrandPicker();

        this.router.events
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((event: Event | RouterEvent) => {
                if (event instanceof NavigationEnd) {
                    this.updateShowBrandPicker();
                }
            });
    }

    public updateShowBrandPicker(): void {
        const isLoggedIn = this.sessionService.isLoggedIn();
        const showBrandPicker = this.appService.settings.brandPicker;
        const havePreviousView = !!this.previousView;
        const notInSettingsOrShowcaseView = !(this.isInSettings() || this.isInShowcase());

        this.showBrandPicker =
            (isLoggedIn && showBrandPicker) || (havePreviousView && notInSettingsOrShowcaseView);
    }

    public isEmployee(): boolean {
        return this.sessionService?.user?.isEmployee;
    }

    public logOut(): void {
        this.sessionService.logOut();
    }

    public switchBrand(brand: any): void {
        this.brandDropDown.toggleCollapsed(true);

        this.navigatorService.switchBrand(brand);
    }

    public toggleWelcomePanel(): void {
        this.appService.toggleWelcomePanel();
    }

    public gotoSupport(): void {
        window.open('https://support.bannerflow.com', '_blank', 'noopener');
    }

    public isInSettings(): boolean {
        const urlArray: string[] = this.navigatorService.getUrlArray();

        return !!~urlArray.indexOf('settings');
    }

    public isInShowcase(): boolean {
        const urlArray: string[] = this.navigatorService.getUrlArray();
        return !!~urlArray.indexOf('/p');
    }

    public isInAccountSettings(): boolean {
        const urlArray: string[] = this.navigatorService.getUrlArray();

        return !~urlArray.indexOf('settings/account');
    }

    public isWelcomeMessageEnabled(): boolean {
        return (
            this.sessionService.hasFeature(SessionService.FEATURES.WELCOMEMESSAGE) &&
            this.appService.settings.welcomePanel
        );
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    public isInProductView(): boolean {
        return this.navigatorService.getUrlArray().includes('product');
    }

    public isBackNavigationVisible(): boolean {
        const isVisible: boolean =
            this.previousView && this.sessionService.isLoggedIn() && this.isInShowcase();

        return isVisible;
    }

    public getBackText(): string {
        if (!this.previousView) {
            return 'Home';
        } else {
            return this.previousView.title;
        }
    }

    public backClick(): void {
        if (!this.previousView) {
            this.sessionService.gotoA1Dashboard();
        } else {
            window.location.href = `${AppConfig.config.B2_URL}/${this.previousView.url}`;
        }
    }

    openIntercomNews(): void {
        Intercom('showSpace', 'news');
    }
}
