import { Component, ElementRef, Input, Renderer2, inject } from '@angular/core';
import { ExObjectItem } from '@shared/classes/ExObjectQuery';
import { ObjectType } from '@shared/enums/objectType.enum';
import { ObjectPickerComponent } from '../objectPicker.component';
import { firstValueFrom } from 'rxjs';
import { CampaignApiService } from '@core/services/api/campaign/campaign-api.service';
import { ReportingLabel } from '@analytics/models/reportingLabel.model';
import { SessionService } from '@core/services/internal/session.service';
import { FormsModule } from '@angular/forms';
import { DebounceInputDirective } from '../../../directives/debounceInput.directive';

import { UIModule } from '@bannerflow/ui';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
    selector: 'reportingLabelPicker',
    templateUrl: 'reporting.label.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    imports: [FormsModule, DebounceInputDirective, UIModule, NgxDatatableModule]
})
export class ReportingPickerComponent extends ObjectPickerComponent {
    private readonly campaignService = inject(CampaignApiService);
    private readonly sessionService = inject(SessionService);
    public type: ObjectType = ObjectType.AdGroup;
    public tableRows: (ReportingLabel | ExObjectItem<any>)[] = [];
    public readonly elementRef = inject(ElementRef);
    public readonly renderer = inject(Renderer2);

    get brandId(): string {
        return this.sessionService.user.brand.id;
    }

    protected loadItems(): void {
        this.loading = true;

        firstValueFrom(this.campaignService.getReportingLabels(this.brandId)).then(
            (labels: ReportingLabel[]) => {
                labels.forEach((label) => {
                    label.id = label.value;
                });
                labels.sort((labelA, labelB) => {
                    if (labelA.value || labelA.value < labelB.value) {
                        return -1;
                    } else if (labelA.value > labelB.value) {
                        return 1;
                    }
                    return 0;
                });

                this.tableRows = labels;
                super.loadItems();
                this.datatable.recalculate();
                this.loading = false;
            }
        );
    }
}
