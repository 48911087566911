<div class="objectPicker">
    <div class="objectPicker__search">
        <input
            type="text"
            placeholder="Search creative set"
            spellcheck="false"
            debounceInput
            (debounceInputChange)="search()"
            [(ngModel)]="searchQuery"
        />
    </div>
    @if (breadcrumbs && breadcrumbs.length) {
<div class="objectPicker__goUp"
         (click)="goUp()"
         (keydown)="($event.key === 'Enter' || $event.key === ' ') && goUp()">
        <i
            aria-hidden="true"
            class="folderIcon folderIcon--symbol bf-icon bf-icon-arrow-left-a"
        ></i>
        Go back
    </div>
}
    <div class="objectPicker__table">
        @if (loading) {
<ui-loader></ui-loader>
}
        <ngx-datatable
            #datatable
            [class.loading-hidden]="loading"
            [selected]="selectedObjects"
            [columnMode]="'force'"
            [messages]="{ emptyMessage: 'No matches for your search' }"
            [rowHeight]="40"
            [rows]="tableRows"
            [selectCheck]="canSelect"
            [selectionType]="multi ? SelectionType.multiClick : SelectionType.single"
            (activate)="onTableClick($event)"
            (selectedChange)="selectedObjects = $event"
        >
            @if (multi) {
<ngx-datatable-column
               
                [width]="30"
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
                [headerCheckboxable]="true"
                [checkboxable]="true"
            >
            </ngx-datatable-column>
}
            <ngx-datatable-column name="Name" prop="data.name">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span
                        [ngClass]="{
                            'live-bannerset':
                                row.data && row.data.impressionsLastWeek > 100 && !row.isFolder,
                            'live-folder':
                                row.data && row.data.impressionsLastWeek > 100 && row.isFolder
                        }"
                    >
                        @if (row.isFolder || row.type === 'Folder') {
<i
                            aria-hidden="true"
                            class="itemIcon itemIcon--folder bf-icon bf-icon-folder-o"
                           
                        >

                            <div class="hidecheckbox"></div>
                        </i>
}
                        @if (!row.isFolder || row.type === 'CreativeSet') {
<i
                            aria-hidden="true"
                            class="itemIcon bf-icon bf-icon-banner-set"
                           
                        ></i>
}
                        @if (showOnlyBannerSets) {
<span>
                            {{ value }}
                        </span>
} @else {

                            <span>
                                {{ row.name }}
                            </span>
                        
}
                        
                        @if (!row.isFolder && showAmountOfBanners) {
<div
                            class="objectPicker__statusSign objectPicker__banners"
                           
                        >
                            Contains {{ row.data.banners }} banners
                        </div>
}
                        @if (row.isFolder || row.type === 'Folder') {
<i
                            aria-hidden="true"
                            class="itemIcon itemIcon--arrow bf-icon bf-icon-arrow-right-b"
                           
                        ></i>
}
                        @if (row.data && row.data.impressionsLastWeek > 1000 && !row.isFolder) {
<div
                           
                            class="objectPicker__statusSign"
                            uiTooltip="This bannerset has received over 1,000 impressions the last 7 days ({{
                                row.data.impressionsLastWeek | number
                            }}). This is updated once a day."
                        >
                            Live
                        </div>
}
                        @if (
                                row.data &&
                                row.data.impressionsLastWeek > 1000 &&
                                (row.isFolder || row.type === 'Folder')
                            ) {
<div
                           
                            class="objectPicker__statusSign objectPicker__statusSign--folder"
                            uiTooltip="Bannersets in this folder have received over 1,000 impressions combined in the last 7 days ({{
                                row.data.impressionsLastWeek | number
                            }}). This is updated once a day."
                        >
                            Live
                        </div>
}
                    </span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
        @if (loading) {
<ui-loader></ui-loader>
}
    </div>
</div>
