import { EventEmitter, Injectable, ViewContainerRef, inject } from '@angular/core';
import { SessionService } from './session.service';

@Injectable()
export class AppService {
    private readonly sessionService = inject(SessionService);
    public settings: AppViewSettings = { sidebar: true, welcomePanel: true, brandPicker: true };
    public rootViewContainer: ViewContainerRef;
    public previousView: any;
    public welcomePanelToggled: EventEmitter<boolean> = new EventEmitter<boolean>();
    public welcomePanelOpened: boolean;
    public isInitialized: boolean;
    public toggleWelcomePanel(show?: boolean): void {
        if (!this.isWelcomeMessageFeatureToggleOn()) {
            return;
        }
        if (this.settings.welcomePanel) {
            this.welcomePanelOpened = show ?? !this.welcomePanelOpened;
        } else {
            this.welcomePanelOpened = false;
        }
        this.welcomePanelToggled.emit(this.welcomePanelOpened);
    }
    public setIsInitialized(): void {
        this.isInitialized = true;
    }
    public isWelcomeMessageFeatureToggleOn(): boolean {
        return this.sessionService.hasFeatures([SessionService.FEATURES.WELCOMEMESSAGE]);
    }
}
export class AppViewSettings {
    public sidebar = true;
    public welcomePanel = false;
    public brandPicker = true;
}
