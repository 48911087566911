import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { UIInputComponent, UIModule } from '@bannerflow/ui';
import { SessionService } from '@core/services/internal/session.service';
import { Constants } from '@shared/constants';
import { Comment } from '@shared/models/comment.model';

import { FormsModule } from '@angular/forms';

@Component({
    selector: 'comment-input',
    styleUrls: ['./comments.component.scss'],
    templateUrl: './commentInput.component.html',
    imports: [UIModule, FormsModule]
})
export class CommentInputComponent implements AfterViewInit {
    sessionService = inject(SessionService);

    @ViewChild('commentInput') public commentInput: ElementRef;
    @ViewChild('commentEmailInput') public commentEmailInput: UIInputComponent;

    @Input() public comment: Comment;
    @Input() public autoFocus = true;
    @Input() public isReply = false;
    @Output('postComment') private readonly _addComment: EventEmitter<any[]> = new EventEmitter();
    public anonymous: boolean = !this.sessionService.user?.id;
    public email: string;
    public message: string;
    public emailError: boolean;
    public isEditingEmail = false;

    public addComment(event: KeyboardEvent | MouseEvent): void {
        if (event instanceof KeyboardEvent && event.key !== Constants.KEYS.ESCAPE) {
            event.stopPropagation();
        }
        if (
            (event instanceof KeyboardEvent && event.key === Constants.KEYS.ENTER) ||
            event instanceof MouseEvent
        ) {
            event.preventDefault();

            if (!this.message) {
                return;
            }

            this._addComment.emit([this.message, this.comment]);
            this.message = '';
        }
    }

    public setEmail(event: KeyboardEvent | MouseEvent): void {
        if (
            (event instanceof KeyboardEvent && event.key === Constants.KEYS.ENTER) ||
            event.type === 'click'
        ) {
            if (!this.email) {
                return;
            }

            this.emailError = false;

            if (!this.validEmail(this.email)) {
                this.emailError = true;

                return;
            }

            this.isEditingEmail = false;
            this.sessionService.setAnonymous(this.email);

            setTimeout(() => {
                this.commentInput.nativeElement.focus();
            });

            this.email = '';
        }
    }

    public clearEmail(event: MouseEvent): void {
        if (this.sessionService.user.email.length > 0) {
            this.email = this.sessionService.user.email;
            this.isEditingEmail = true;
        } else {
            this.isEditingEmail = false;
            this.sessionService.setAnonymous('');
        }

        setTimeout(() => {
            this.commentEmailInput.focus();
        });

        event.preventDefault();
        event.stopPropagation();
    }

    public ngAfterViewInit(): void {
        if (this.autoFocus) {
            setTimeout(() => {
                if (
                    this.anonymous &&
                    !this.sessionService.user?.email
                ) {
                    this.commentEmailInput.focus();
                } else {
                    this.commentInput.nativeElement.focus();
                }
            });
        }
    }

    public validEmail(email: string): boolean {
        return /(.+)@(.+){2,}\.(.+){2,}/.test(email);
    }
}
