import { Routes } from '@angular/router';
import { SessionService } from '@core/services/internal/session.service';
import { AppViewComponent } from './app.view.component';
import { userResolver } from './user.resolver';
import { AuthGuard } from '@auth0/auth0-angular';
import { FeatureGuard } from '@shared/guards/feature.guard';

export const APP_ROUTES: Routes = [
    {
        path: ':accountSlug/:brandSlug',
        component: AppViewComponent,
        resolve: { user: userResolver },
        canActivate: [AuthGuard],
        children: [
            {
                path: 'analytics',
                canActivate: [FeatureGuard, AuthGuard],
                data: { requiredFeatures: [SessionService.FEATURES.ANALYTICS], allMustMatch: true },
                loadChildren: () =>
                    import('@analytics/analytics.module').then((m) => m.AnalyticsModule)
            },
            {
                path: '**',
                redirectTo: '404'
            }
        ]
    },
    {
        path: '**',
        redirectTo: '404'
    }
]
