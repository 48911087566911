import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mapProperty',
})
export class MapPropertyPipe implements PipeTransform {

    public static mapProperty(array: any[], path: string, defaultValue?: any) {
        if (!array?.length) {
            return [];
        }
        if (!path) {
            return array;
        }
        const data = array.map((item: any) => MapPropertyPipe.getPropertyByPath(item, path, defaultValue));
        return data;
    }
    public static getPropertyByPath(obj: any, path: string, defaultValue?: any): any {
        if (path === undefined) {
            return undefined;
        } else if (path === '') {
            return obj;
        }
        const fullPath: string[] = path
            .replace(/\[/g, '.')
            .replace(/]/g, '')
            .split('.')
            .filter(Boolean);

        return fullPath.every(everyFunc) ? obj : defaultValue;

        function everyFunc(step: any): any {
            return !(step && obj && (obj = obj[step]) === undefined);
        }
    }
    public transform(array: any[], path: string, defaultValue?: any): any[] {
        return MapPropertyPipe.mapProperty(array, path, defaultValue);
    }
}
