import { Serializable } from '@shared/interfaces/serializable.interface';
import { Culture } from './culture.model';

export class Localization implements Serializable<Localization> {
    public id: string;
    public culture: Culture;
    public name: string;
    public mainLocalization: boolean;
    public targetUrl: string;

    public deserialize(json: any): this {
        if (!json) {
            return null;
        }

        this.id = json.id;
        this.culture = new Culture().deserialize(json.culture);
        this.name = json.name;
        this.mainLocalization = json.mainLocalization;
        this.targetUrl = json.targetUrl ? json.targetUrl : '';

        return this;
    }

    public serialize(): LocalizationDto {
        const dto: LocalizationDto = new LocalizationDto();
        dto.name = this.name;
        dto.targetUrl = this.targetUrl;
        dto.mainLocalization = this.mainLocalization ? this.mainLocalization : false;
        dto.cultureId = this.culture.id;

        return dto;
    }
}

export class LocalizationDto {
    public name: string;
    public targetUrl: string;
    public mainLocalization: boolean;
    public cultureId?: string;
}
