import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'objectSearch',
})
export class ObjectSearchPipe implements PipeTransform {
    public transform(value: any, keys: string, term: string): any {
        if (!term) {
            return value;
        }
        const result: any[] = (value || []).filter((item: any) =>
            keys
                .split(',')
                .some((key) => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key]))
        );
        return result.length ? result : [-1];
    }
}
