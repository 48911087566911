import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UINotificationService } from '@bannerflow/ui';
import { AppConfig } from '@config/app.config';
import { ApiService, BFHttpError, IApiOptions } from '@core/services/api/api.service';
import { SessionService } from '@core/services/internal/session.service';
import { ILocation } from '@analytics/models/analyticsLocation.model';
import { CreativeSet } from '@shared/models/studio/creativeset.model';
import { firstValueFrom, TimeoutError } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { AnalyticsDateSpan } from '../models/analyticsDateSpan.model';
import { IHeatmap } from '../models/analyticsHeatmap.model';
import { IMergedSizeFormat } from '../models/size/analyticsMergedSizeFormat.model';
import { ISize } from '@shared/models/size';
import { Campaign } from '@shared/models/campaign/models/campaign.model';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsApiService {
    private readonly sessionService = inject(SessionService);
    private readonly apiService = inject(ApiService);
    private readonly notificationService = inject(UINotificationService);
    private readonly httpClient = inject(HttpClient);
    private readonly authService = inject(AuthService);

    private requestTimeoutInMs: number;

    private getApiUrl(): string {
        return `${AppConfig.config.ANALYTICS_BACKEND_SERVICE_URL}/api/v2/${this.sessionService.user.account.slug}/${this.sessionService.user.brand.slug}/analytics`;
    }

    private getDataApiUrl(): string {
        return `${AppConfig.config.ANALYTICS_BACKEND_SERVICE_URL}/api/v2/${this.sessionService.user.account.slug}/${this.sessionService.user.brand.slug}/data`;
    }

    private getCampaignUrl(): string {
        return `${AppConfig.config.ANALYTICS_BACKEND_SERVICE_URL}/api/v2/${this.sessionService.user.account.slug}/${this.sessionService.user.brand.slug}/campaign`;
    }

    public async getCreativeSetsByIds(creativeSetIds: string[]): Promise<CreativeSet[]> {
        const options: IApiOptions = {
            cache: true,
            anonymous: true,
            errorNotification: true
        };

        return this.apiService.post(
            `${this.getDataApiUrl()}/creativesetsbyid`,
            creativeSetIds,
            options
        );
    }

    public async getMergedSizeFormats(queryParameters: any): Promise<IMergedSizeFormat[]> {
        const options: IApiOptions = {
            anonymous: true,
            queryParameters,
            errorNotification: true
        };
        return this.apiService.get(`${this.getDataApiUrl()}/brandsizes`, options);
    }

    public async getHeatmap(
        creativeId: string,
        queryParameters: any,
        size: ISize,
        adId?: string
    ): Promise<IHeatmap> {
        const maxWidth = size.width;
        const maxHeight = size.height;

        queryParameters = {
            ...queryParameters,
            maxWidth,
            maxHeight
        };

        if (adId) {
            queryParameters = {
                ...queryParameters,
                adId
            };
        }

        const options: IApiOptions = {
            anonymous: true,
            queryParameters,
            errorNotification: true
        };

        return this.apiService.get(`${this.getApiUrl()}/creative/${creativeId}/heatmap`, options);
    }

    private handleError(err: any): void {
        const error: BFHttpError = err;
        this.notificationService.open('Something went wrong!', {
            type: 'error',
            placement: 'top',
            icon: 'close',
            autoCloseDelay: 5000
        });

        throw error;
    }

    protected async setupHeaders(): Promise<HttpHeaders> {
        const token: string = await this.authService.getAccessTokenSilently().toPromise();
        return new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        });
    }

    public async getTopLocations(
        { from, to }: AnalyticsDateSpan,
        numberOfResults: number,
        { objectType, value }: { objectType: string; value: string }
    ): Promise<ILocation> {
        let headers: HttpHeaders = await this.setupHeaders();
        const apiUrl = `${AppConfig.config.ANALYTICS_BACKEND_SERVICE_URL}/api/v2/${this.sessionService.user.account.slug}/${this.sessionService.user.brand.slug}/analytics/location/impressions`;
        let params: HttpParams = new HttpParams()
            .append('from', from.toISOString())
            .append('to', to.toISOString())
            .append('limit', numberOfResults.toString())
            .append(objectType, value);

        this.requestTimeoutInMs = this.sessionService.hasFeature(
            SessionService.FEATURES.FEATURE_ANALYTICS_DEMO_DATA
        ) ? 3000 : 30000;

        try {
            return firstValueFrom(
                this.httpClient.get<ILocation>(apiUrl, { params, headers }).pipe(timeout(this.requestTimeoutInMs))
            );
        } catch (err) {
            if (err instanceof TimeoutError) {
                err = new BFHttpError({
                    statusText: `Request timed out after ${this.requestTimeoutInMs}ms.`,
                    status: 408
                } as Response);
            }
            this.handleError(err);
        }
    }

    public async getCampaign(campaignId: string): Promise<Campaign> {
        try {
            const response: any = await this.apiService.get(`${this.getCampaignUrl()}/${campaignId}`);
            return new Campaign().deserialize(response);
        } catch (error) {
            this.handleError(error);
        }
    }
}
