import { Component, Input } from '@angular/core';
import { ObjectDropDownComponent } from '../objectDropDown.component';
import { NgClass } from '@angular/common';
import { BFDropDownComponent } from '../../dropDown/bfDropDown.component';
import { ReportingPickerComponent } from './reprting.label.picker.component';

@Component({
    selector: 'reportingLabel',
    templateUrl: './reporting.label.component.html',
    styleUrls: ['../objectDropDown.component.scss'],
    imports: [NgClass, BFDropDownComponent, ReportingPickerComponent]
})
export class ReportingLabelComponent extends ObjectDropDownComponent {
    @Input() public placeholder = 'No reporting labels selected';
}
